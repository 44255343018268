import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {Card, CardContent, Grid} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {PlayerDetails, ShuffleRaffle} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import {useParams} from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import '../Assets/CSS/Giveaways.scss'
import '../Assets/CSS/Giveaways.responsive.scss'
import RaffleTicketWidget from "../Components/RaffleTicketWidget";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        maxWidth: 150,
        height: "190px",
        backgroundColor: 'transparent !important',
        margin: '5px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        background: '#121418',
        height: '188px',
        borderRadius: '10px',
        border: '1px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        padding: '0 !important',
        color: '#cecece'
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getRaffles = async (username?: string): Promise<Array<ShuffleRaffle>> =>
    axiosGet(username ? `/general/raffles?username=${username}`:`/general/raffles`)

export type RaffleProps = {
    userDetails: PlayerDetails | undefined
};

export const Raffle: React.FC<RaffleProps> = ({userDetails}) => {
    const classes = useStyles();

    const ParticipantsList = ( classes: any, participants: any) => {
        return (<Grid className={classes.gridList} spacing={0} container>
            {
                Object.keys(participants).map((key: any) => {
                    return (
                        <Card sx={{minWidth: 150}} className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <div className="Participant-image">
                                    <img src={'https' + participants[key].split('_https')[1]} />
                                </div>
                                <h3>{participants[key].split('_https')[0]}</h3>
                                <div className="RaffleSpend">
                                    <p style={{display: 'flex', justifyContent: 'center'}}>
                                        <div className="Balance">
                                        <img src="/currency.svg" />
                                        <div  className="Points-value">{(Number(key.split('-')[1]) - Number(key.split('-')[0])).toLocaleString('en-US')}</div>
                                    </div></p>
                                </div>
                                <h5>{key}</h5>
                            </CardContent>
                        </Card>
                    );
                })}
        </Grid>);
    }

    const [open, setOpen] = React.useState(false);
    const [viewing, setViewing] = useState<ShuffleRaffle | undefined>(undefined);

    const params = useParams();
    const raffleId = params.raffle

    const handleClose = () => {
        setOpen(false)
    };

    useEffect(() => {
        getRaffles(userDetails?.username).then(resp => {
            if (raffleId && resp) {
                setViewing(resp.find(r => r.id == raffleId))
            }
        });
    }, [])

    if (viewing) {
        return (<>
            <div className="App-contents VIP Raffle-view">
                <div className="Inspect-return">
                    <a href="https://btcs.gg/raffles">
                        <ArrowBack />
                        <span>Raffles</span>
                    </a>
                </div>
                <div className="Raffle-Main">
                    <div className="Raffle-overview">
                        <div className="Raffle-image">
                            <img src={viewing.image} />
                        </div>
                        <div className="Raffle-details RaffleContent-View">
                            <div className="RaffleHeading">{viewing.name}</div>
                            <div className="RaffleHeading">$10,000 Crypto</div>
                            <div className="Entries-list">
                                <div className="Entries">
                                    <ul>
                                        <li>Entries</li>
                                        <li>{viewing.ticketsSold.toLocaleString('en-US')}</li>
                                    </ul>
                                </div>
                                <div className="Entries">
                                    <ul>
                                        <li>Your Entries</li>
                                        <li>{viewing.soldToMe.toLocaleString('en-US')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Entries-list">
                                <div className="Streamer">
                                    <ul>
                                        <li>Streamer</li>
                                        <li>{viewing.streamer}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Entries-list">
                                <div className="RaffleAction" onClick={() => {
                                    setOpen(true)
                                }}>
                                    Buy Tickets
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Raffle-status">
                    Bets Open
                </div>
                <div className="Raffle-participants">
                    <h2>Raffle Bets</h2>
                    <div className="Raffle-bets-table">
                        <div className="table-header">
                            <div className="Active-filter">
                                <div className="Active">All</div>
                                <div>Your Bets</div>
                            </div>
                        </div>
                        <div className="raffle-table">
                            {
                                ParticipantsList(classes, viewing.participants)
                            }
                        </div>
                        <div className="table-pagination">

                        </div>
                    </div>
                </div>
                <RaffleTicketWidget
                    open={open}
                    onClose={handleClose}
                    viewing={viewing}
                />
                <Footer/>
            </div>

        </>);
    }

    return (<>
        <div className="App-contents VIP">
            <div className="VIP-container">
                <h2>Loading...</h2>
            </div>
        </div>
    </>)
}