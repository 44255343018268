
export function numberWithCommas(x: number) {
    const fractions = x.toString().split(".")
    const twoDecimal = fractions.length > 1 ? fractions[1].substring(0, 2) : '00'
    return fractions[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + twoDecimal;
}

export const calculateVIPRewards = (wager: number, withComma?: boolean) => {
    let rewardNumber = 0;
    if (wager < 10000) {
        rewardNumber = 0;
    }
    if (wager >= 10000 && wager < 50000) {
        rewardNumber = 20;
    }
    if (wager >= 50000 && wager < 100000) {
        rewardNumber = 100;
    }
    if (wager >= 100000 && wager < 250000) {
        rewardNumber = 200;
    }
    if (wager >= 250000 && wager < 500000) {
        rewardNumber = 500;
    }
    if (wager >= 500000 && wager < 1000000) {
        rewardNumber = 1000;
    }
    if (wager >= 1000000 && wager < 2500000) {
        rewardNumber = 2000;
    }
    if (wager >= 2500000 && wager < 5000000) {
        rewardNumber = 5000;
    }
    if (wager >= 5000000 && wager < 10000000) {
        rewardNumber = 10000;
    }
    if (wager >= 10000000 && wager < 50000000) {
        rewardNumber = 20000;
    }
    if (wager >= 50000000) {
        rewardNumber = 100000;
    }

    const rewardNumberWithComma = numberWithCommas(rewardNumber)

    return withComma ? rewardNumberWithComma.substring(0,rewardNumberWithComma.length - 3) : rewardNumber;
}

export function msToTime(ms: number): any {
    let days = Math.floor(ms / (24*60*60*1000));
    let daysms= ms % (24*60*60*1000);
    let hours = Math.floor((daysms)/(60*60*1000));
    let hoursms=ms % (60*60*1000);
    let minutes = Math.floor((hoursms)/(60*1000));
    let minutesms=ms % (60*1000);
    let sec = Math.floor((minutesms)/(1000));
    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: sec
    };
}

export const chunk = (arr: Array<any>, size: number): Array<Array<any>> => {
    const newArr = [];
    while(arr.length) newArr.push(arr.splice(0, size));
    return newArr;
}

export const endDate = (end: any): Array<string> => {
    const fix = (value: number): string => {
        return value < 10 ? '0' + value.toFixed(0) : value.toFixed(0);
    }
    var delta = Math.abs(Date.parse(end) - new Date().getTime()) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = delta % 60;

    return [ fix(days), fix(hours), fix(minutes), fix(seconds) ]
}