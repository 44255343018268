import React from "react";

export type FooterProps = {};

export const Footer: React.FC<FooterProps> = () => {
    return (<footer className="App-footer">
        <div className="Footer-Main">
            <div className="Footer-socials">
                <ul>
                    <li><a href="https://kick.com/btcs"><img width={32} src="https://btcs.gg/footer/kick-icon.svg"/></a></li>
                    <li><a href="https://discord.gg/btcs"><img width={50} src="https://btcs.gg/footer/discord-icon.svg"/></a></li>
                    <li><a href="https://youtube.com/btcs"><img width={55} src="https://btcs.gg/footer/youtube-icon.svg"/></a></li>
                    <li><a href="https://x.com/btcstv"><img width={40} src="https://btcs.gg/footer/x-icon.svg"/></a></li>
                </ul>
            </div>
            <div className="Footer-navigation">
                <ul>
                    <li><a href="https://btcs.gg/privacy-policy"><span>Privacy Policy</span></a></li>
                    <li><a href="https://btcs.gg/faq"><span>FAQs</span></a></li>
                    <li><a href="https://btcs.gg/tos"><span>Terms of Service</span></a></li>
                </ul>
            </div>
        </div>
        <div className="Footer-Signature">
            <span>contact@btcs.gg All Right Reserved 2024.</span>
        </div>
    </footer>);
}