import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {PlayerDetails, ShuffleRaffle} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import {formatDateYearTime} from "../Components/Crates";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift, faTicketAlt} from "@fortawesome/free-solid-svg-icons";
import RaffleTicketWidget from "../Components/RaffleTicketWidget";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        maxWidth: 300,
        height: "560px",
        backgroundColor: 'transparent !important',
        margin: '25px',
        border: '1px solid #2a2e38',
        boxShadow: 'none',
    },
    cardContent: {
        background: '#121418',
        height: '560px',
        borderTop: '1px solid #2d007d',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece'
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getRaffles = async (username?: string): Promise<Array<ShuffleRaffle>> =>
    axiosGet(username ? `/general/raffles?username=${username}`:`/general/raffles`)

export type RaffleProps = {
    userDetails: PlayerDetails | undefined
};

export const Raffles: React.FC<RaffleProps> = ({userDetails}) => {

    const RaffleList = (data: Array<ShuffleRaffle>, setJoining: any) => {
        const classes = useStyles();
        return (<Grid className={classes.gridList} spacing={0} container>
            {
                data.map((tile) => {
                    return (
                        <Card sx={{minWidth: 300}} className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <div className="RaffleContent">
                                    <span>Ends {formatDateYearTime(tile.end)}</span>
                                    <br/>  <br/>
                                    <Typography gutterBottom variant="h6" component="div">
                                        <div className="RaffleHeading">{tile.name}</div>
                                    </Typography>
                                    <br /> <br />
                                    <div className="RaffleIcon">
                                        <img src={tile.image} />
                                    </div>
                                    <br /> <br />
                                    <Typography gutterBottom component="div">
                                        <div className="RaffleHeading">$10,000 Crypto</div>
                                    </Typography>
                                    <br /> <br />
                                    <div className="Entries-list">
                                        <div className="Entries">
                                            <ul>
                                                <li>Entries</li>
                                                <li>{tile.ticketsSold.toLocaleString('en-US')}</li>
                                            </ul>
                                        </div>
                                        <div className="Entries">
                                            <ul>
                                                <li>Your Entries</li>
                                                <li>{tile.soldToMe.toLocaleString('en-US')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="Entries-list">
                                        <div className="Streamer">
                                            <ul>
                                                <li>Streamer</li>
                                                <li>{tile.streamer}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="Entries-list">
                                        <a href={`https://btcs.gg/raffle/${tile.id}/`}><div className="RaffleAction">
                                            View
                                        </div></a>
                                        <div className="RaffleAction" onClick={() => setJoining(tile)}>
                                            Join
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    );
                })}
        </Grid>);
    }


    const [data, setData] = useState<Array<ShuffleRaffle>>([]);
    const [joining, setJoining] = useState<ShuffleRaffle | undefined>(undefined);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getRaffles(userDetails?.username).then(r => {
            setData(r)
        });
    }, [])

    return (<>

        <div className="App-contents Challenges VIP">

            <div className="Landing-content">
                <br/><br/>
                <div className="Landing-heading">
                    <h2 className="Main-heading Main-heading-larger">Raffles</h2>
                    <div className="Heading-divider"/>
                    <p className="Main-subheading">BTCs Raffles are active every month!</p>
                    <a href="https://btcs.gg/giveaways"><Button size="small" className="Rules-button">
                        <FontAwesomeIcon icon={faTicketAlt}/>
                        <p className="Rules-btn">Giveaways</p>
                    </Button></a>
                </div>
                <br/><br/><br/><br/><br/>


                <div className="Bonus-hunts-container">
                    <div className="Bonus-hunts-content-container">

                        <div className="RaffleList">
                            { RaffleList(data, (raffle: ShuffleRaffle) => { setOpen(true); setJoining(raffle); }) }
                        </div>

                    </div>
                </div>
                <br/> <br/>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/>


            <RaffleTicketWidget
                open={open}
                onClose={handleClose}
                viewing={joining!}
            />
            <Footer/>
        </div>
    </>)
}