import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {formatDateYearTime} from "./Crates";
import {numberWithCommas} from "../Utility/utils";
import {BonusHunt, ItemDefinition, ObtainedItem} from "../API/api";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
            background: 'transparent !important',
            borderRadius: '10px'
        }
    },
    table: {
        border: '1px solid #2a2e38 !important',
        borderRadius: '10px'
    },
    tableHeader: {
        background: '#121418 !important',
        border: '1px solid #2a2e38 !important',
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            background: '#1b1d27 !important'
        },
        "&:nth-of-type(even)": {
            background: '#121418 !important'
        },
        border: '1px solid #2a2e38 !important',
    },
    headerCell: {
        background: '#121418 !important',
        color: 'rgb(223, 218, 228) !important',
        borderBottom: '1px solid #2a2e38 !important',
        "&:first-child": {
            borderTopLeftRadius: '10px'
        },
    },
    tableCell: {
        color: 'rgb(223, 218, 228) !important',
        borderBottom: '1px solid #2a2e38 !important',
        cursor: 'pointer'
    }
}))

interface Column {
    id: 'id' | 'source' | 'item' | 'name' | 'quantity' | 'updated' | 'created'| 'status';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any, defs?: Array<ItemDefinition>) => any;
}

const columns: readonly Column[] = [
    { id: 'item', label: 'Item', minWidth: 100, format: (v: any, defs?: Array<ItemDefinition>) => {
            const def = defs?.find(d => d.id == v)
            return <img src={def ? def.image : "/logo.gif"} alt={def?.name ?? v} width={35} height={35} /> ?? 'N/A'
        } },
    { id: 'name', label: 'Name', minWidth: 100, format: (v: any, defs?: Array<ItemDefinition>) => {
            return v ?? 'N/A '
        }},
    { id: 'source', label: 'Source', minWidth: 100, format: (v: any) => v ?? 'N/A' },
    {
        id: 'updated',
        label: 'Date',
        minWidth: 170,
        align: 'right',
        format: (value: any) => value ? formatDateYearTime(value) : 'N/A'
    },
    { id: 'status', label: 'Status', minWidth: 100, format: (v: any) => v ?? 'N/A' },
];

export type PlayerItemsProps = {
    rows: Array<ObtainedItem>,
    defs: Array<ItemDefinition>
};

export const PlayerItems: React.FC<PlayerItemsProps> = ({
    rows,
    defs
}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const classes = useStyles();
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
            <TableContainer sx={{ maxHeight: 840 }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead
                        className={classes.tableHeader}
                    >
                        <TableRow className={classes.tableRow}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={classes.headerCell}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow className={classes.tableRow} hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            return (
                                                <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                                                    <span>{// @ts-ignore
                                                        column.format(value, defs)}</span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                className={"HuntsPagination"}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}