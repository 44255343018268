import React from "react";
import {PlayerDetails} from "../API/api";
import StarIcon from '@mui/icons-material/Star';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import SettingsModal from "./SettingsModal";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
            background: 'transparent !important',
            borderRadius: '10px'
        }
    },
    menuItem: {
        background: 'red !important'
    },
    menu: {
        // background: 'red'
    }
}))

export type HeaderProps = {
    userDetails: PlayerDetails | undefined
    togglePanelVisible: any
};

export const Login = () => {
    const client_id = process.env.REACT_APP_TWITCH_CLIENT_ID;
    const redirect_uri = process.env.REACT_APP_TWITCH_REDIRECT_URI;
    const response_type = 'code';
    const scopes = [
        'chat',
        'edit+chat',
        'read+openid',
    ];
    const scope = scopes.reduce((s1, s2) => s1 + ':' + s2);
    const params = `client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
    const url = `https://id.twitch.tv/oauth2/authorize?${params}`;
    return { url: url }
}

export const Header: React.FC<HeaderProps> = ({userDetails, togglePanelVisible}) => {
    const classes = useStyles();


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState<boolean>(false)

    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {url} = Login()
 
    function getProfile(
        userDetails: PlayerDetails | undefined,
        handleClick: any,
        handleClose: any,
        openMenu: any,
        anchorEl: any,
        classes: any
    ) {

        if (!userDetails) {
            return (<>
            <div className="App-userbar" style={{width: '80px', minWidth: '130px'}}>
                <ul>
                    <li>
                        <a href={url}>
                        <button>Login</button>
                    </a>
                    </li>
                </ul>
            </div>
            <div className="App-userbar-mobile">
                <ul>
                    <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                </ul>
            </div>
            </>)
        }
        return (<>
            <div className="App-userbar">
                <ul>
                    <li className="Profile-image" onClick={handleClick}><img className="ProfileImage" src={userDetails.profilePicture} /></li>
                    <li className="User-details" onClick={handleClick}>
                        <p>{userDetails.username}</p>
                        <div className="Balance">
                            <img src="/newcoin.svg" />
                            <span>{userDetails.points.toLocaleString('en-US')}</span>
                        </div>
                    </li>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        className={"Desktop-nav"}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem classes={classes.menuItem} onClick={() => window.location.href = 'https://btcs.gg/user'}>Profile</MenuItem>
                        <MenuItem classes={classes.menuItem} onClick={() => setOpen(true)}>Settings</MenuItem>
                        {
                            userDetails!.authority == 'USER' ? <></> :
                                <MenuItem onClick={() => window.location.href = 'https://btcs.gg/admin/dashboard'}>Admin</MenuItem>

                        }
                        <MenuItem classes={classes.menuItem} onClick={() => {
                            localStorage.removeItem("bearer");
                            localStorage.removeItem("refresh");
                            localStorage.removeItem("expiry");
                            localStorage.removeItem("twitch");
                            window.location.reload()
                        }}>Logout</MenuItem>
                    </Menu>

                </ul>
                </div>
                <div className="App-userbar-mobile">
                    <ul>
                        <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                    </ul>
                </div>


            <SettingsModal
                open={open}
                onClose={() => setOpen(false)}
                details={userDetails}
            />
                </>)
        }

    return (<header className="App-header">
        <div className="App-header-main">
            <div className="App-logo" onClick={() => window.location.href = 'https://btcs.gg/'}>
                <img width={45} height={45} src="/btcs-logo.png"/>
            </div>
            <div className="App-horizontal-nav">
                <ul>
                    {
                        navigationItems
                            .filter(item => item.iconOnly)
                            .map(item => {
                            return (
                                <li><a href={`https://btcs.gg${item.url}`}>
                                    {getIcon(item.title)}
                                    <p>{item.title}</p>
                                </a>
                                </li>
                            )
                        })
                    }
                </ul>
                <ul>
                    {
                        navigationItems
                            .filter(item => !item.iconOnly)
                            .map(item => {
                            return (
                                <li><a href={`https://btcs.gg${item.url}`}>
                                    {getIcon(item.title)}
                                    <p>{item.title}</p>
                                </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="App-bar-right">
                <div className="Leaderboard-header-button" onClick={() => window.location.href = 'https://btcs.gg/leaderboard'}>
                    <div className="Leaderboard-icon">
                        {getIcon('Leaderboard')}
                    </div>
                    <div className="Leaderboard-content">
                        <ul>
                            <li>Leaderboard</li>
                            <li>$25,000</li>
                        </ul>
                    </div>
                </div>
                {getProfile(userDetails, handleClick, handleClose, openMenu, anchorEl, classes)}
            </div>
        </div>
    </header>);
}


const navigationItems = [
    {title: 'VIP', width: 25, url: '/vip', iconOnly: true},
    {title: 'Casino', width: 25, url: '/casino', iconOnly: false},
    // {title: 'Slot Battles', width: 25, url: '/battles'},
    {title: 'Challenges', width: 25, url: '/challenges'},
    // {title: 'Hunts', width: 25, url: '/hunts'},
    // {title: 'Store', width: 25, url: '/store'},
    // {title: 'Highlights', width: 25, url: '/highlights'},
    {title: 'Giveaways', width: 25, url: '/giveaways'},
]

function getIcon(title: string) {
    if (true) {
        switch (title) {
            case 'Leaderboard': {
                return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 84.464 65.533" width={25}>
                    <defs>
                        <style></style>
                        <clipPath id="clippath">
                            <rect className="cls-2" x="0" y=".006" width="84.464" height="65.527"/>
                        </clipPath>
                    </defs>
                    <g id="Group_48">
                        <g className="cls-1">
                            <g id="Group_47">
                                <path id="Path_79" className="cls-3"
                                      d="m84.375,10.724c-.681-4.452-4.841-7.509-9.293-6.828-3.981.609-6.922,4.033-6.922,8.061-.016,2.559,1.17,4.977,3.205,6.53l-15.283,12.51-9.971-15.668c3.961-2.142,5.436-7.09,3.294-11.051-2.142-3.961-7.09-5.436-11.051-3.294-3.961,2.142-5.436,7.09-3.294,11.051.754,1.395,1.899,2.54,3.294,3.294l-9.961,15.66-15.327-12.542c.111-.084.219-.17.325-.259,2.181-1.823,3.252-4.653,2.825-7.464-.573-3.614-3.464-6.416-7.094-6.877C4.649,3.313.592,6.505.058,10.977.019,11.3,0,11.624,0,11.949c-.037,3.473,2.158,6.578,5.445,7.701.187.062.377.118.567.168l7.619,45.716h57.188l7.622-45.726c3.99-1.088,6.533-4.992,5.916-9.081m-44.148-5.051c.567-.475,1.282-.736,2.021-.74.242,0,.483.026.72.076,1.725.395,2.804,2.114,2.409,3.839s-2.114,2.804-3.839,2.409c-1.725-.395-2.804-2.114-2.409-3.839.159-.695.545-1.317,1.098-1.768M6.999,14.956c-1.255-.467-2.079-1.676-2.053-3.015,0-.916.391-1.788,1.075-2.396.586-.525,1.346-.814,2.132-.811.132,0,.263.007.394.022,1.757.212,3.01,1.808,2.798,3.565-.098.812-.503,1.557-1.132,2.08-.896.737-2.113.947-3.205.555m71.362-.555c-.898.742-2.122.953-3.217.555-1.254-.468-2.077-1.676-2.051-3.015-.003-1.77,1.429-3.207,3.199-3.21.135,0,.27.008.405.025,1.414.19,2.54,1.278,2.778,2.684.172,1.113-.249,2.234-1.112,2.958"/>
                            </g>
                        </g>
                    </g>
                </svg>
            }
            case 'Store': {
                return (
                    <svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                        <defs>
                            {/*<style>.cls-1{fill:#606780;stroke-width:0px;}</style>*/}
                        </defs>
                        <g id="icons_Q2">
                            <path id="Path_43" className="cls-1"
                                  d="m88.395,16.76c-1.264-1.301-2.99-2.052-4.804-2.089H19.891l-.835-7.101c-.421-3.131-3.107-5.458-6.266-5.43h-6.475c-2.307,0-4.177,1.87-4.177,4.177s1.87,4.177,4.177,4.177h4.595l6.683,56.182c-3.766,2.224-6.071,6.278-6.057,10.652,0,6.921,5.61,12.531,12.531,12.531,5.305-.083,10.023-3.394,11.905-8.354h22.139c1.882,4.961,6.6,8.271,11.905,8.354,6.921,0,12.531-5.61,12.531-12.531s-5.61-12.531-12.531-12.531c-5.305.083-10.023,3.394-11.905,8.354h-22.139c-1.611-4.421-5.564-7.567-10.234-8.145l-.418-4.386h52.84c3.079-.075,5.647-2.377,6.057-5.43l5.639-32.999c.233-1.931-.291-3.877-1.462-5.43Zm-14.202,60.568c0,2.307-1.87,4.177-4.177,4.177s-4.177-1.87-4.177-4.177,1.87-4.177,4.177-4.177,4.177,1.87,4.177,4.177Zm-45.948,0c0,2.307-1.87,4.177-4.177,4.177s-4.177-1.87-4.177-4.177,1.87-4.177,4.177-4.177,4.177,1.87,4.177,4.177Z"/>
                        </g>
                    </svg>
                )
            }
            case 'Casino': {
                return (
                    <svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92"><defs>
                    </defs>
                        <path d="m78.204,8.091h-38.041c-.991,0-1.795.804-1.795,1.795v12.942c0,.908.736,1.644,1.644,1.644h17.376c.991,0,1.795.804,1.795,1.795v37.122c0,.825.669,1.494,1.494,1.494h17.526c.991,0,1.795-.804,1.795-1.795V9.886c0-.991-.804-1.795-1.795-1.795Z"/><path d="m54.459,27.116H16.418c-.991,0-1.795.804-1.795,1.795v53.202c0,.991.804,1.795,1.795,1.795h38.041c.991,0,1.795-.804,1.795-1.795V28.911c0-.991-.804-1.795-1.795-1.795Zm-8.983,30.547l-9.176,12.233c-.431.574-1.292.574-1.723,0l-9.176-12.233c-.287-.383-.287-.91,0-1.293l9.176-12.233c.431-.574,1.292-.574,1.723,0l9.176,12.233c.287.383.287.91,0,1.293Z"/></svg>
                )
            }
            case 'Challenges': {
                return (<svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <g id="Layer_7">
                        <g id="Group_19">
                            <rect id="Rectangle_107" className="cls-1" x="62.966" y="5.268" width="20.366"
                                  height="81.464" rx="4.073" ry="4.073"/>
                            <rect id="Rectangle_108" className="cls-1" x="34.453" y="35.817" width="20.366"
                                  height="50.915" rx="4.073" ry="4.073"/>
                            <rect id="Rectangle_109" className="cls-1" x="5.941" y="64.329" width="20.366"
                                  height="22.403" rx="4.073" ry="4.073"/>
                        </g>
                    </g>
                </svg>)
            }
            case 'Highlights': {
                return (<svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <g id="icons_Q2">
                        <path id="Path_44" className="cls-1"
                              d="m45.806,5.031C22.422,5.138,3.553,24.182,3.66,47.565c.107,23.384,19.151,42.253,42.534,42.146,23.384-.107,42.253-19.151,42.146-42.534-.051-11.162-4.508-21.852-12.401-29.745-8-7.97-18.841-12.431-30.133-12.401Zm20.494,43.887l-31.9,19.527c-1.16.773-2.127.193-2.127-1.16V27.845c0-1.353.967-1.933,2.127-1.16l31.9,19.527c.747.414,1.018,1.355.605,2.102-.141.254-.35.464-.605.605Z"/>
                    </g>
                </svg>)
            }
            case 'Slot Battles': {
                return (<svg id="Layer_1" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <path className="cls-1"
                          d="m81.981,71.472c.399-.372.68-.644.972-.904,1.555-1.386,3.784-1.374,5.26.026,1.54,1.46,1.832,3.807.407,5.332-2.477,2.651-5.045,5.224-7.694,7.702-1.603,1.499-3.787,1.265-5.309-.3-1.454-1.495-1.484-3.728-.069-5.351.255-.292.524-.573.855-.935-1.978-1.981-3.903-3.95-5.894-5.849-.252-.24-.882-.234-1.288-.137-3.678.881-7.343,1.821-11.018,2.717-2.255.55-4.334-.713-4.903-2.933-.517-2.015.751-4.145,2.909-4.713,3.658-.964,7.328-1.881,11.006-2.763.688-.165.971-.495,1.127-1.138.855-3.521,1.737-7.037,2.619-10.552.64-2.55,2.645-3.86,4.999-3.283,2.136.524,3.276,2.707,2.669,5.162-.87,3.519-1.744,7.036-2.651,10.545-.194.75-.128,1.282.483,1.863,1.856,1.767,3.634,3.615,5.521,5.51Z"/>
                    <path className="cls-1"
                          d="m15.715,77.05c1.536,1.374,2.379,2.84,1.735,4.815-.821,2.516-3.977,3.689-5.939,1.939-2.821-2.516-5.469-5.24-8.047-8.01-1.361-1.463-.985-3.819.53-5.227,1.457-1.354,3.647-1.377,5.171-.05.324.282.635.58,1.024.937,2.008-2.009,4.004-3.963,5.928-5.985.229-.241.173-.877.073-1.287-.876-3.598-1.803-7.183-2.69-10.779-.495-2.009.306-3.884,1.974-4.745,1.605-.829,3.617-.443,4.805,1.049.447.562.742,1.301.928,2.007.902,3.428,1.769,6.866,2.585,10.316.187.79.488,1.175,1.321,1.369,3.53.822,7.043,1.719,10.554,2.622,2.511.645,3.837,2.839,3.121,5.081-.652,2.044-2.644,3.196-4.738,2.688-3.676-.892-7.35-1.793-11.01-2.746-.747-.195-1.236-.084-1.776.471-1.786,1.837-3.624,3.622-5.551,5.535Z"/>
                    <path className="cls-1"
                          d="m75.998,5.898c-4.205,1.051-8.402,2.135-12.617,3.147-1.318.317-2.389.935-3.335,1.888-4.311,4.34-8.644,8.658-12.972,12.982-.3.3-.617.584-.978.925-.362-.341-.679-.626-.979-.926-4.329-4.322-8.638-8.665-13.003-12.949-.709-.696-1.597-1.399-2.524-1.651-4.676-1.27-9.375-2.466-14.1-3.538-3.173-.719-5.507,1.795-4.738,4.973,1.066,4.403,2.123,8.81,3.33,13.174.32,1.155,1.008,2.316,1.821,3.206,2.021,2.212,4.213,4.268,6.334,6.389,2.508,2.508,5.013,5.019,7.653,7.662-1.567,1.538-3.006,3-4.5,4.403-1.288,1.21-1.592,2.651-1.25,4.316.538,2.625,1.069,5.252,1.581,7.882.397,2.041,1.556,3.283,3.63,3.682,2.632.506,5.269.998,7.876,1.613,1.855.438,3.309-.019,4.583-1.402,1.355-1.47,2.781-2.874,4.261-4.394,1.643,1.67,3.13,3.19,4.628,4.7,1.007,1.014,2.163,1.492,3.64,1.174,2.812-.605,5.637-1.154,8.464-1.687,2.131-.402,3.279-1.688,3.671-3.778.471-2.518.941-5.038,1.511-7.534.436-1.913.037-3.467-1.428-4.81-1.444-1.324-2.801-2.744-4.23-4.156.402-.421.693-.737.997-1.041,4.324-4.327,8.688-8.615,12.945-13.007.833-.86,1.501-2.049,1.825-3.204,1.179-4.209,2.193-8.464,3.246-12.708.963-3.884-1.464-6.299-5.339-5.331ZM22.338,22.431c-.439-.444-.756-1.089-.931-1.697-.519-1.807-.939-3.642-1.456-5.701,2.363.598,4.55,1.143,6.728,1.717.205.054.371.283.537.449,4.294,4.285,8.587,8.572,12.878,12.86.109.109.198.236.326.391-1.688,1.686-3.302,3.298-4.997,4.991-.219-.19-.488-.397-.726-.634-4.128-4.117-8.263-8.227-12.359-12.375Zm37.467,28.209c-.45,3.55-.768,3.859-4.402,4.281-.266.031-.627-.068-.824-.242-.996-.883-1.953-1.811-2.869-2.671,1.789-1.772,3.432-3.4,5.249-5.2.824.864,1.787,1.829,2.683,2.854.187.214.203.658.162.978Zm10.755-29.062c-.083.309-.405.57-.652.818-10.65,10.655-21.307,21.302-31.945,31.969-.507.508-.945.626-1.649.485-3.394-.679-3.425-.649-3.908-4.108-.063-.452.151-1.101.473-1.424,10.655-10.704,21.338-21.38,32.024-32.053.242-.242.537-.504.852-.588,2.078-.555,4.169-1.065,6.47-1.643-.58,2.307-1.095,4.433-1.664,6.544Z"/>
                </svg>)
            }
            case 'VIP': {
                return (<svg id="Layer_1" className="VIP" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <g id="invisible_box">
                        <rect id="Rectangle_16" className="cls-1" x="-3.35" y="-3.351" width="98.699" height="98.699"/>
                    </g>
                    <g id="icons_Q2">
                        <path id="Path_19" className="cls-2"
                              d="m46,2.817c-1.55.035-2.962.898-3.7,2.261l-11.722,23.853-26.318,3.702c-2.335.483-3.836,2.768-3.353,5.103.163.786.541,1.51,1.092,2.093l18.916,18.305-4.32,26.316c-.342,2.246,1.202,4.343,3.448,4.685.15.023.302.037.454.043l2.066-.411,23.442-12.337,23.442,12.337,2.066.411c2.27-.091,4.036-2.004,3.946-4.274-.006-.152-.021-.304-.043-.454l-4.329-26.316,18.918-18.301c1.642-1.729,1.571-4.462-.158-6.104-.583-.554-1.309-.933-2.096-1.097l-26.327-3.702-11.722-23.853c-.738-1.363-2.15-2.226-3.7-2.261Z"/>
                    </g>
                </svg>)
            }
            case 'Hunts': {
                return (<svg id="Layer_1" className="Hunts" width={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">

                    <g id="invisible_box">
                        <rect id="Rectangle_102" className="cls-1" x="-5.565" y="-5.529" width="103.13"
                              height="103.13"/>
                    </g>
                    <circle className="cls-4" cx="46" cy="46" r="7.267"/>
                    <circle className="cls-3" cx="46" cy="46" r="18.963"/>
                    <circle className="cls-3" cx="46" cy="46" r="29.43"/>
                    <line className="cls-2" x1="46" y1="24.554" x2="46" y2="4.845"/>
                    <line className="cls-2" x1="46" y1="87.155" x2="46" y2="67.446"/>
                    <line className="cls-2" x1="67.446" y1="46" x2="87.155" y2="46"/>
                    <line className="cls-2" x1="4.845" y1="46" x2="24.554" y2="46"/>
                </svg>)
            }
        }
    }
    switch (title) {
        case 'Leaderboard': {
            return (<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group">
                        <path d="M8 13.5V10.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path className="Fill" d="M6 13.5H10C10.5304 13.5 11.0391 13.7107 11.4142 14.0858C11.7893 14.4609 12 14.9696 12 15.5H4C4 14.9696 4.21071 14.4609 4.58579 14.0858C4.96086 13.7107 5.46957 13.5 6 13.5V13.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 1.5H15V4.5C15 5.29565 14.6839 6.05871 14.1213 6.62132C13.5587 7.18393 12.7956 7.5 12 7.5V7.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4 1.5H1V4.5C1 5.29565 1.31607 6.05871 1.87868 6.62132C2.44129 7.18393 3.20435 7.5 4 7.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path className="Fill" d="M4 0.5H12V6.5C12 7.56087 11.5786 8.57828 10.8284 9.32843C10.0783 10.0786 9.06087 10.5 8 10.5C6.93913 10.5 5.92172 10.0786 5.17157 9.32843C4.42143 8.57828 4 7.56087 4 6.5V0.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            );

        }
        case 'Vip': {
            return (<StarIcon className="VIP-icon" />)
        }
        case 'Store': {
            return (<svg focusable="false"
                         aria-hidden="true" width="25" height="25" viewBox="0 0 24 24">
                <path
                    d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" stroke="#FFFFFF"/>
            </svg>)
        }
        case 'Highlights': {
            return (<svg width="25" height="25" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.50008 15.3333C12.5502 15.3333 15.8334 12.0501 15.8334 8.00001C15.8334 3.94992 12.5502 0.666672 8.50008 0.666672C4.44999 0.666672 1.16675 3.94992 1.16675 8.00001C1.16675 12.0501 4.44999 15.3333 8.50008 15.3333Z" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.16675 10.6667V5.33334L11.1667 8.00001L7.16675 10.6667Z" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/></svg>);
        }
        case 'Challenges': {
            return (<svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2257_3383)">
                    <path d="M7.02424 7.712L8.81091 13.666C8.83836 13.7578 8.88916 13.8408 8.95833 13.907C9.0275 13.9733 9.11268 14.0204 9.20553 14.0439C9.29839 14.0673 9.39573 14.0663 9.48806 14.0408C9.58038 14.0154 9.66452 13.9664 9.73224 13.8987L10.6422 12.9887L12.9902 15.3367C13.0416 15.3881 13.1026 15.4288 13.1698 15.4567C13.2369 15.4845 13.3089 15.4988 13.3816 15.4988C13.4543 15.4988 13.5262 15.4845 13.5934 15.4567C13.6605 15.4288 13.7215 15.3881 13.7729 15.3367L15.3382 13.7713C15.3896 13.72 15.4304 13.6589 15.4582 13.5918C15.4861 13.5247 15.5004 13.4527 15.5004 13.38C15.5004 13.3073 15.4861 13.2354 15.4582 13.1682C15.4304 13.1011 15.3896 13.0401 15.3382 12.9887L12.9902 10.6407L13.9002 9.73334C13.968 9.66566 14.017 9.58157 14.0425 9.48928C14.0679 9.397 14.0691 9.29968 14.0457 9.20684C14.0223 9.114 13.9752 9.02881 13.9091 8.9596C13.8429 8.8904 13.7599 8.83954 13.6682 8.812L7.71358 7.02534C7.61812 6.99701 7.51679 6.99493 7.42025 7.01932C7.3237 7.04371 7.23552 7.09367 7.16498 7.16395C7.09443 7.23422 7.04413 7.32221 7.01936 7.41865C6.9946 7.5151 6.99628 7.61644 7.02424 7.712Z" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.4822 7.00001C14.3867 5.66146 13.9083 4.3786 13.1043 3.30423C12.3002 2.22985 11.2042 1.40924 9.94694 0.940146C8.68965 0.47105 7.32402 0.37324 6.01271 0.658364C4.7014 0.943489 3.49968 1.59953 2.5507 2.54835C1.60171 3.49717 0.945453 4.69877 0.660094 6.01002C0.374736 7.32128 0.472303 8.68693 0.941174 9.94431C1.41005 11.2017 2.23046 12.2978 3.30469 13.1021C4.37893 13.9063 5.6617 14.3849 7.00023 14.4807" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.2089 6C10.9613 5.38754 10.5658 4.84594 10.0578 4.42359C9.54984 4.00124 8.94515 3.71128 8.29778 3.57961C7.65041 3.44794 6.9805 3.47867 6.34789 3.66904C5.71529 3.85941 5.13968 4.20349 4.67248 4.67057C4.20529 5.13765 3.86105 5.71316 3.67052 6.34572C3.47999 6.97828 3.4491 7.64818 3.5806 8.29559C3.7121 8.94299 4.00191 9.54775 4.42413 10.0558C4.84635 10.5639 5.38784 10.9595 6.00024 11.2073" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                </g><defs><clipPath id="clip0_2257_3383"><rect width="25" height="25" fill="#FFFFFF"/></clipPath></defs></svg>);
        }
        case 'Hunts': {
            return (<svg width="25" height="25" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="Fill" fill="#FFFFFF" d="m432.748 79.196c-47.219-47.219-109.999-73.224-176.776-73.224s-129.559 26.004-176.777 73.224c-47.219 47.219-73.223 109.999-73.223 176.776s26.004 129.558 73.223 176.777c97.701 97.699 255.841 97.711 353.554 0 97.699-97.7 97.712-255.84-.001-353.553zm-176.776 406.776c-126.822 0-230-103.178-230-230s103.178-230 230-230 230 103.178 230 230-103.178 230-230 230z" />
                <path className="Fill" fill="#FFFFFF" d="m255.972 75.972c-99.253 0-180 80.747-180 180 0 99.251 80.747 180 180 180 99.252 0 180-80.748 180-180s-80.748-180-180-180zm0 340c-88.225 0-160-71.775-160-160 0-88.224 71.775-160 160-160s160 71.776 160 160-71.776 160-160 160z" />
                <path className="Fill" fill="#FFFFFF" d="m255.972 145.972c-60.654 0-110 49.346-110 110s49.346 110 110 110 110-49.346 110-110-49.346-110-110-110zm0 200c-49.626 0-90-40.374-90-90s40.374-90 90-90 90 40.374 90 90-40.374 90-90 90z" />
                <path className="Fill" fill="#FFFFFF" d="m255.972 215.972c-22.057 0-40 17.944-40 40 0 22.055 17.943 40 40 40 22.056 0 40-17.944 40-40s-17.945-40-40-40zm0 60c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
            </svg>)
        }
        case 'Slot Battles': {
            return (<svg width="25" height="25" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <g><g>
                    <path className="Fill" d="m356.17 89.283-145.169 355.941c-6.346 15.39-23.719 22.45-38.791 16.342-15.231-6.267-22.529-23.56-16.342-38.791l145.17-356.021c6.187-15.23 23.56-22.529 38.791-16.263 15.23 6.187 22.528 23.56 16.341 38.792z"/>
                    <path className="Fill" d="m250.268 50.57c-15.151-6.346-32.603.873-38.871 16.024l-74.012 178.169-74.013-178.169c-6.267-15.151-23.719-22.37-38.871-16.024-15.151 6.267-22.369 23.719-16.103 38.871l101.539 244.329c10.075 24.353 44.82 24.353 54.894 0l101.54-244.329c6.267-15.152-.952-32.604-16.103-38.871zm5.077 34.27-101.46 244.329c-6.108 14.674-26.892 14.674-33 0l-101.46-244.329c-3.808-9.043.555-19.514 9.598-23.322 9.043-3.729 19.594.555 23.322 9.678l79.566 191.337c1.824 4.443 9.122 4.443 10.947 0l79.486-191.337c3.808-9.123 14.279-13.407 23.402-9.678 9.043 3.808 13.406 14.279 9.599 23.322z"/>
                    <path className="Fill" d="m414.634 281.175c-17.532 0-31.81-14.2-31.81-31.731s14.279-31.81 31.81-31.81h61.479c16.42 0 29.748-13.326 29.748-29.748 0-16.342-13.327-29.748-29.748-29.748h-61.479c-50.373 0-91.306 40.932-91.306 91.306 0 50.293 40.933 91.227 91.306 91.227 17.452 0 31.731 14.279 31.731 31.81s-14.279 31.731-31.731 31.731h-61.558c-16.421 0-29.748 13.406-29.748 29.748 0 16.42 13.327 29.748 29.748 29.748h61.558c50.293 0 91.227-40.933 91.227-91.227s-40.933-91.306-91.227-91.306zm0 170.634h-61.558c-9.837 0-17.849-8.012-17.849-17.849s8.012-17.849 17.849-17.849h61.558c24.036 0 43.63-19.594 43.63-43.63 0-24.116-19.594-43.709-43.63-43.709-43.789 0-79.407-35.539-79.407-79.327s35.618-79.407 79.407-79.407h61.479c9.837 0 17.849 8.012 17.849 17.849s-8.012 17.849-17.849 17.849h-61.479c-24.116 0-43.709 19.594-43.709 43.709 0 24.036 19.594 43.63 43.709 43.63 43.709 0 79.327 35.618 79.327 79.407s-35.618 79.327-79.327 79.327z"/>
            </g></g></svg>)
        }
        case 'Giveaways': {
            return (<svg width="18" height="18" style={{paddingTop: '15px'}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 293.373 293.373">
                <g><g><g>
                <path className="Fill" d="M130.265,162.904c-24.162,0-95.274,0-95.274,0c-7.549,0-13.726,6.176-13.726,13.725v103.02 c0,7.549,6.177,13.725,13.726,13.725c0,0,72.6,0,96.876,0c3.229,0,3.229-3.469,3.229-3.469V167.568 C135.095,167.568,135.095,162.904,130.265,162.904z"/>
                    <path className="Fill" d="M258.383,162.904c0,0-71.384,0-95.177,0c-5.797,0-4.929,6.037-4.929,6.037v121.076c0,0-0.047,3.354,3.44,3.354 c24.166,0,96.664,0,96.664,0c7.549,0,13.726-6.176,13.726-13.725V176.629C272.109,169.08,265.932,162.904,258.383,162.904z"/>
                </g><g>
                    <path className="Fill" d="M135.095,81.846c0,0,0-4.651-4.596-4.651c-27.521,0-111.008,0-111.008,0c-7.549,0-13.726,6.177-13.726,13.725v42.845 c0,7.549,6.177,13.725,13.726,13.725c0,0,83.861,0,111.384,0c4.22,0,4.22-3.66,4.22-3.66V81.846L135.095,81.846z"/>
                    <path className="Fill" d="M273.882,77.195c0,0-83.521,0-111.362,0c-4.241,0-4.241,4.041-4.241,4.041v62.679c0,0,0,3.575,5.156,3.575 c27.611,0,110.447,0,110.447,0c7.549,0,13.726-6.176,13.726-13.725V90.92C287.608,83.372,281.431,77.195,273.882,77.195z"/>
                </g><g><path className="Fill" d="M88.41,67.04c-6.28,0-12.016-0.498-17.046-1.481c-12.776-2.496-21.557-7.354-26.845-14.85 c-4.738-6.718-6.188-15-4.311-24.617C43.496,9.266,54.796,0,72.024,0c3.646,0,7.65,0.421,11.902,1.252 c10.816,2.113,24.65,8.315,37.007,16.59c20.965,14.041,22.002,22.77,20.958,28.115c-1.535,7.854-8.876,13.466-22.443,17.158 C110.282,65.609,98.969,67.04,88.41,67.04z M72.025,21.999c-6.672,0-8.965,1.864-10.224,8.311 c-1.03,5.271,0.269,7.112,0.695,7.717c1.784,2.53,6.431,4.64,13.086,5.939c3.591,0.702,8.028,1.073,12.827,1.073 c10.553,0,19.85-1.599,26.019-3.348c0.449-0.127,1.146-0.658,0.399-1.103c-8.065-6.57-22.82-15.343-35.119-17.746 C76.843,22.284,74.257,21.999,72.025,21.999z"/>
                </g><g><path className="Fill" d="M205.281,67.04c0,0,0,0-0.002,0c-10.559,0-21.871-1.431-31.037-3.925c-13.568-3.691-20.908-9.304-22.443-17.157 c-1.043-5.345-0.008-14.074,20.959-28.115c12.355-8.275,26.189-14.477,37.007-16.59c4.252-0.831,8.256-1.252,11.899-1.252 c17.232,0,28.531,9.267,31.816,26.093c1.879,9.616,0.43,17.898-4.309,24.616c-5.288,7.497-14.068,12.354-26.847,14.85 C217.296,66.541,211.56,67.04,205.281,67.04z M179.039,40.452c-0.715,0.415-0.369,1.07,0.002,1.177 c6.166,1.773,15.561,3.411,26.238,3.411c4.801,0,9.236-0.371,12.828-1.073c6.654-1.3,11.303-3.409,13.086-5.939 c0.428-0.605,1.728-2.446,0.695-7.717C230.63,23.864,228.336,22,221.663,22c-2.231,0-4.815,0.284-7.682,0.844 C201.681,25.246,187.104,33.881,179.039,40.452z"/>
            </g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>)
        }
        case 'Casino': {
            return (<svg width="25" height="25" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.83966 1.30632C8.81482 1.1779 8.76491 1.05563 8.69279 0.946511C8.62066 0.837389 8.52773 0.743553 8.41932 0.670369C8.31091 0.597185 8.18913 0.546087 8.06096 0.519999C7.93278 0.49391 7.80072 0.493341 7.67232 0.518324L1.80566 1.65699C1.54676 1.70829 1.31863 1.85985 1.17102 2.07865C1.02341 2.29744 0.968284 2.56572 1.01766 2.82499L2.72632 11.625C2.77665 11.8843 2.9279 12.1129 3.14681 12.2607C3.36572 12.4085 3.63437 12.4633 3.89366 12.413L4.44366 12.3063"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.07037 4.55699L4.98303 6.79766L5.48503 7.25099" stroke="#FFFFFF" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M2.82361 2.98099C2.85597 2.97473 2.88926 2.97494 2.92154 2.98162C2.95382 2.98831 2.98446 3.00132 3.01167 3.01992C3.03889 3.03852 3.06215 3.06234 3.0801 3.08998C3.09806 3.11763 3.11035 3.14856 3.11628 3.18099"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M2.62564 3.27365C2.61937 3.24129 2.61959 3.208 2.62627 3.17572C2.63295 3.14344 2.64597 3.11281 2.66457 3.08559C2.68317 3.05837 2.70698 3.03511 2.73463 3.01716C2.76228 2.9992 2.79321 2.98691 2.82564 2.98099"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M2.91828 3.47166C2.88592 3.47783 2.85266 3.47755 2.82041 3.47083C2.78817 3.46411 2.75757 3.45108 2.73037 3.4325C2.70317 3.41391 2.67992 3.39014 2.66194 3.36253C2.64396 3.33493 2.63161 3.30405 2.62561 3.27166"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M3.11638 3.17832C3.12265 3.21073 3.12245 3.24405 3.11577 3.27638C3.1091 3.3087 3.09609 3.33938 3.07749 3.36665C3.0589 3.39393 3.03509 3.41724 3.00744 3.43527C2.97979 3.45329 2.94884 3.46566 2.91638 3.47166"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M15.4667 4.74872L8.96412 3.42812C8.6033 3.35484 8.25139 3.58794 8.17811 3.94876L6.26282 13.3796C6.18954 13.7404 6.42264 14.0923 6.78346 14.1656L13.286 15.4862C13.6469 15.5595 13.9988 15.3264 14.0721 14.9655L15.9874 5.53473C16.0606 5.17391 15.8275 4.822 15.4667 4.74872Z"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M10.7323 11.4117L10.9296 10.4357" stroke="#FFFFFF" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path
                    d="M11.6203 7.01833C11.6203 7.01833 9.95827 7.69899 9.76093 8.67499C9.72206 8.86734 9.72146 9.06545 9.75915 9.25803C9.79684 9.45061 9.8721 9.63387 9.98062 9.79736C10.1998 10.1276 10.5411 10.3572 10.9296 10.4357C11.3181 10.5142 11.7218 10.4351 12.052 10.216C12.3822 9.9968 12.6118 9.65545 12.6903 9.26699C12.8876 8.29033 11.6203 7.01833 11.6203 7.01833Z"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M9.7196 5.10899C9.78458 5.12217 9.84166 5.16062 9.87829 5.21588C9.91492 5.27114 9.92811 5.33868 9.91494 5.40366"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M9.42505 5.30433C9.43823 5.23935 9.47667 5.18227 9.53193 5.14564C9.58719 5.10901 9.65474 5.09583 9.71972 5.10899"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M9.62036 5.59966C9.55538 5.58648 9.4983 5.54804 9.46167 5.49278C9.42504 5.43752 9.41186 5.36997 9.42503 5.30499"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M9.91503 5.40366C9.90185 5.46863 9.86341 5.52571 9.80815 5.56234C9.75289 5.59898 9.68534 5.61216 9.62036 5.59899"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M12.6323 13.319C12.6973 13.3322 12.7544 13.3706 12.791 13.4259C12.8276 13.4811 12.8408 13.5487 12.8277 13.6137"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M12.339 13.5143C12.3522 13.4493 12.3906 13.3923 12.4459 13.3556C12.5011 13.319 12.5687 13.3058 12.6337 13.319"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M12.5343 13.809C12.4694 13.7957 12.4124 13.7572 12.3758 13.702C12.3392 13.6467 12.326 13.5793 12.339 13.5143"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M12.8323 13.6137C12.8191 13.6786 12.7806 13.7357 12.7254 13.7723C12.6701 13.809 12.6026 13.8222 12.5376 13.809"
                    stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>)
        }
    }
    return (<svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7654 6.10154L8.00311 1L1.23458 6.10154C1.09465 6.23065 1.01051 6.40917 1 6.59925V14.3897C1.00049 14.5509 1.06476 14.7054 1.17878 14.8194C1.29279 14.9334 1.44729 14.9976 1.60853 14.9981H5.66667V11.7338C5.66667 11.115 5.9125 10.5216 6.35008 10.0841C6.78767 9.64655 7.38116 9.40075 8 9.40075C8.61884 9.40075 9.21233 9.64655 9.64992 10.0841C10.0875 10.5216 10.3333 11.115 10.3333 11.7338V15H14.3908C14.5521 14.9995 14.7067 14.9353 14.8208 14.8213C14.9349 14.7073 14.9993 14.5528 15 14.3915V6.59925C14.9892 6.40923 14.9051 6.23081 14.7654 6.10154Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
    );
}