import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AdminNavigation} from "../Components/Admin/AdminNavigation";
import '../Assets/CSS/Admin.scss';
import Dialog from '@mui/material/Dialog';
import {
    BlacklistRequest,
    Challenge, ConcludeSlotBattleRequest,
    CreateChallengeRequest,
    CreateGiveawayRequest,
    CreateHighlightRequest,
    CreateItemDefinitionRequest,
    CreateSlotBattleRequest,
    CreateStoreItemRequest,
    EditChallengeRequest,
    EditGiveawayRequest,
    EditHighlightRequest,
    EditItemDefinitionRequest,
    EditSlotBattleRequest,
    EditStoreItemRequest,
    Giveaway,
    HighlightClip, Item,
    ItemDefinition,
    LogDTO,
    LogPlayer, ObtainedItem, PlatformOverview,
    Player, PlayerAddItemRequest,
    PlayerDetails, PlayerMoveItemRequest,
    ShuffleRaffle,
    ShuffleSlotsGame,
    SlotBattle,
    StaticList,
    StoreItem, WatchTime
} from "../API/api";
import {axiosGet, axiosGetBlob, axiosPost, axiosPut, backend, postFile} from "../Utility/httpClient";
import {formatDateYearTime} from "../Components/Crates";
import Tabs from "../Components/Tabs/Tabs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {createEditor, Node} from 'slate'
import {Editable, Slate, withReact} from 'slate-react'
import ArrowBack from "@mui/icons-material/ArrowBack";
import {Card, CardContent, Checkbox, FormControlLabel, Grid} from "@mui/material";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            color: '#acacac',
            background: '#121418'
        },
    },
};

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

export interface ModalProps {
    open: boolean;
    onClose: () => void;
}

export type HighlightModalProps = {
    editing?: HighlightClip
    setItems: any;
} & ModalProps

export type ChallengeModalProps = {
    editing?: Challenge
    setItems: any;
} & ModalProps

export type ItemModalProps = {
    items: Array<ItemDefinition>;
    editing?: ItemDefinition
    setItems: any;
} & ModalProps

export type StoreItemModalProps = {
    items: Array<ItemDefinition>;
    editing?: StoreItem
    setItems: any;
} & ModalProps

export type GiveawayModalProps = {
    editing?: Giveaway
    setGiveaways: any
} & ModalProps

export type BlacklistModalProps = {
    strings: string
    title: string
    endpoint: string
} & ModalProps

export type ConcludeChallengeModalProps = {
    editing?: Challenge
    players: Array<Player>;
} & ModalProps

export type SlotBattleModalProps = {
    editing?: SlotBattle
    setItems: any;
} & ModalProps

/* Frontend */
export type SettingsModalProps = {
    details?: PlayerDetails
} & ModalProps

export type RafflePurchaseTicketModalProps = {
    viewing: ShuffleRaffle
} & ModalProps

function AddChallengeModal(props: ChallengeModalProps) {
    const { onClose, open, setItems } = props;

    const [name, setName] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [multiplier, setMultiplier] = useState<number | undefined>(undefined);
    const [wager, setWager] = useState<number | undefined>(undefined);
    const [tip, setTip] = useState<number | undefined>(undefined);
    const [end, setEnd] = useState<string | undefined>(undefined);
    const [timed, setTimed] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const createChallenge = async (request: CreateChallengeRequest): Promise<Array<Challenge>> => axiosPost(`/admin/create-challenge`, request)
    const editChallenge = async (request: EditChallengeRequest): Promise<Array<Challenge>> => axiosPut(`/admin/edit-challenge`, request)

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (props.editing) {
            setName(props.editing.name)
            setImage(props.editing.image)
            setMultiplier(props.editing.targetMultiplier)
            setWager(props.editing.minimumWagerRequired)
            setEnd(props.editing.endDate)
            setTip(props.editing.reward)
            setTimed(props.editing.timed)
        }
    }, [])

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Create'} Shuffle Challenge</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Challenge Name</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/profile/name.png"/>
                        </div>
                        <input type="text" placeholder="Challenge Name..." defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Challenge Image</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/profile/img.png"/>
                        </div>
                        <input type="text" placeholder="Challenge Image..." defaultValue={image}
                               onChange={(e) => {
                                   setImage(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Hit (x)</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/profile/multiply.png"/>
                        </div>
                        <input type="number" placeholder="Multiplier" defaultValue={multiplier}
                               onChange={(e) => {
                                   setMultiplier(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>And Bet at least ($)</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/navigation/btc.svg"/>
                        </div>
                        <input type="number" placeholder="Bet Min." defaultValue={wager}
                               onChange={(e) => {
                                   setWager(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>To Win (Shuffle Tip)</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/profile/shuffle.png"/>
                        </div>
                        <input type="number" placeholder="Tip" defaultValue={tip}
                               onChange={(e) => {
                                   setTip(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Challenge End</h6>
                    <div className="Form-input">
                        <input type="datetime-local" defaultValue={end}
                               onChange={(e) => {
                                   setEnd(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Timed Challenge</h6>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="Timed"
                                    checked={timed}
                                    onChange={e => setTimed(!timed)}
                                />
                            }
                            label="Timed"/>
                </div>
                <div className="Form-submission">
                    <button onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!name || !image || !multiplier || !wager || !tip) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editChallenge({
                                id: props.editing.id,
                                name: name,
                                image: image,
                                target: multiplier,
                                min: wager,
                                reward: tip,
                                end: end,
                                timed: timed
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setImage(undefined)
                                setMultiplier(undefined)
                                setWager(undefined)
                                setTip(undefined)
                                setTimed(false)
                                setItems(response)
                                onClose()
                            });
                        } else {
                            createChallenge({
                                name: name,
                                image: image,
                                target: multiplier,
                                min: wager,
                                reward: tip,
                                end: end,
                                timed: timed
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setImage(undefined)
                                setMultiplier(undefined)
                                setWager(undefined)
                                setTip(undefined)
                                setTimed(false)
                                setItems(response)
                                onClose()
                            });
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Create') + ' Challenge'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function ConcludeChallengeModal(props: ConcludeChallengeModalProps) {
    const classes = useStyles();
    const { onClose, open, players } = props;

    const [warned, setWarned] = useState<boolean>(false);
    const [selected, setSelected] = useState<Player | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [search, setSearch] = useState<string | undefined>(undefined);

    const concludeWinner = async (request: ConcludeSlotBattleRequest): Promise<Array<Challenge>> => axiosPut(`/moderate/set-challenge-winner`, request)

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>Conclude Challenge</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Winner</h6>
                </div>

                <div className="Form-input-field">
                    <div className="Games-search-result">
                        <div>
                            <div className="Bonus-hunt-search">
                                <div className="search-input">
                                    <img src="/navigation/search.svg" width={20} />
                                    <input type="text" placeholder="Search" defaultValue={search} onChange={e => setSearch(e.target.value)}/>
                                </div>
                            </div>
                            <div className="Btcs-table">
                                <ul className="Header">
                                    <li>Name</li>
                                    <li>Rank</li>
                                    <li>Last Online</li>
                                </ul>
                                {
                                    players.filter(player => {
                                        return !search || player.username.toLowerCase().includes(search.toLowerCase())
                                    }).map(player => {
                                        return <ul className={"Entry " + (player== selected ? 'SelectedEntry' : '')} onClick={() => {
                                            setSelected(player)
                                        }}>
                                            <li>{player.username}</li>
                                            <li>{player.authority}</li>
                                            <li>{formatDateYearTime(player.lastOnline)}</li>
                                        </ul>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            name="Warning"
                            checked={warned}
                            onChange={e => setWarned(!warned)}
                        />
                    }
                    label="I understand that this action cannot be undone."/>

                <div className="Form-submission">
                    <button className={warned && selected ? '' : 'Alt'} onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!selected || !warned) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            concludeWinner({
                                id: props.editing.id,
                                game: selected.username
                            }).then(r => {
                                setWarned(false)
                                setSelected(undefined)
                                setSubmitting(false)
                                handleClose()
                            })
                        }
                    }}>End Challenge & Reward Player</button>
                </div>
            </div>
        </Dialog>
    );
}

function AddHighlightModal(props: HighlightModalProps) {
    const { onClose, open, setItems } = props;
    const classes = useStyles();

    const [title, setTitle] = useState<string | undefined>(undefined);
    const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);
    const [game, setGame] = useState<string | undefined>(undefined);
    const [multiplier, setMultiplier] = useState<number>(0.0);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [submittingHighlight, setSubmittingHighlight] = useState<boolean>(false);
    const [type, setType] = useState<number>(0);

    const addHighlight = async (request: CreateHighlightRequest): Promise<HighlightClip> => axiosPost(`/moderate/create-highlight`, request)
    const editHighlight = async (request: EditHighlightRequest): Promise<Array<HighlightClip>> => axiosPut(`/moderate/edit-highlight`, request)

    const handleClose = () => {
        onClose();
    };

    const handleTypeChange = (event: any) => {
        setType(Number(event.target.value))
    };

    const clipTypes = [ {id: 0, text: "GENERAL"},  {id: 1, text: "WIN_HIGHLIGHT"}, {id: 2, text: "TOP_VIEWER_BUYS"},  {id: 3, text: "ALL_TIME"}, ]

    useEffect(() => {
        if (props.editing) {
            setTitle(props.editing.title)
            setThumbnail(props.editing.thumbnail)
            setUrl(props.editing.url)
            setType(clipTypes.find(t  => t.text == props.editing!.type)?.id ?? 0)
            setGame(props.editing.game)
            setMultiplier(props.editing.multiplier)
        }
    }, [])
    const types = [ "GENERAL", "WIN_HIGHLIGHT", "TOP_VIEWER_BUYS", "ALL_TIME" ]


    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Create'} Highlight Clip</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Clip Title</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Clip Name..." defaultValue={title}
                               onChange={(e) => {
                                   setTitle(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <div className="Form-input ModalSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Type</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={types[type]}
                                onChange={handleTypeChange}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }} label="Type" />}
                                renderValue={(selected) => selected}
                                MenuProps={MenuProps}
                            >
                                {
                                    clipTypes.map((variant) => (
                                        //@ts-ignore
                                        <MenuItem key={variant.id} value={variant.id}>
                                            <ListItemText primary={variant.text} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="Form-input-field">
                    <h6>Thumbnail URL</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Clip Thumbnail..." defaultValue={thumbnail}
                               onChange={(e) => {
                                   setThumbnail(e.target.value)
                               }}/>
                    </div>
                </div>

                <div className="Form-input-field">
                    <h6>Shuffle Game</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Game..." defaultValue={game}
                               onChange={(e) => {
                                   setGame(e.target.value)
                               }}/>
                    </div>
                </div>

                <div className="Form-input-field">
                    <h6>Shuffle Multiplier</h6>
                    <div className="Form-input">
                        <input type="number" min={1.0} step={0.1} defaultValue={multiplier}
                               onChange={(e) => {
                                   setMultiplier(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Clip URL</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="https://youtube.com/..."defaultValue={url}
                               onChange={(e) => {
                                   setUrl(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submittingHighlight) {
                            return
                        }
                        if (!thumbnail || !title || !url || !type || !game || !multiplier) {
                            return
                        }
                        setSubmittingHighlight(true)
                        if (props.editing) {
                            editHighlight({
                                id: props.editing.id,
                                title: title,
                                thumbnail: thumbnail,
                                url: url,
                                type: types[type],
                                game: game,
                                multiplier: multiplier
                            }).then(response => {
                                setSubmittingHighlight(false)
                                setTitle(undefined)
                                setThumbnail(undefined)
                                setUrl(undefined)
                                setItems(response)
                                onClose()
                            });
                        } else {
                            addHighlight({
                                title: title,
                                thumbnail: thumbnail,
                                url: url,
                                type: types[type],
                                game: game,
                                multiplier: multiplier
                            }).then(response => {
                                setSubmittingHighlight(false)
                                setTitle(undefined)
                                setThumbnail(undefined)
                                setUrl(undefined)
                                setItems(response)
                                onClose()
                            });
                        }
                    }}>{submittingHighlight ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Video' }</button>
                </div>
            </div>
        </Dialog>
    );
}

function AddItemModal(props: ItemModalProps) {
    const { onClose, open, setItems } = props;

    const [name, setName] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const createItem = async (request: CreateItemDefinitionRequest): Promise<Array<StoreItem>> => axiosPost(`/admin/create-item`, request)
    const editItem = async (request: EditItemDefinitionRequest): Promise<Array<StoreItem>> => axiosPut(`/admin/edit-item`, request)

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        console.log(props.editing)
        if (props.editing) {
            setName(props.editing.name)
            setImage(props.editing.image)
            setDescription(props.editing.description)
        }
    }, [])

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Add'} Item</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Item Name</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Item Name..." defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Image URL</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Item Image..." defaultValue={image}
                               onChange={(e) => {
                                   setImage(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Description</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Item Description..."defaultValue={description}
                               onChange={(e) => {
                                   setDescription(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!name || !image || !description) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editItem({
                                id: props.editing.id,
                                name: name,
                                image: image,
                                description: description,
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setImage(undefined)
                                setDescription(undefined)
                                onClose()
                                setItems(response)
                            });
                        } else {
                            createItem({
                                name: name,
                                image: image,
                                description: description,
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setImage(undefined)
                                setDescription(undefined)
                                onClose()
                                setItems(response)
                            });
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Create')+' Item'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function AddStoreItemModal(props: StoreItemModalProps) {
    const { onClose, open, setItems } = props;

    const [item, setItem] = useState<number>(1);
    const [cost, setCost] = useState<number | undefined>(undefined);
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const createStoreItem = async (request: CreateStoreItemRequest): Promise<StoreItem> => axiosPost(`/admin/create-store-item`, request)
    const editStoreItem = async (request: EditStoreItemRequest): Promise<Array<StoreItem>> => axiosPut(`/admin/edit-store-item`, request)

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        console.log(props.editing)
        if (props.editing) {
            setItem(props.editing.definition!.id)
            setCost(props.editing.cost)
            setQuantity(props.editing.quantity)
        }
    }, [])

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Add'} Store Item</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Item</h6>
                    <div className="Form-input">
                        <select defaultValue={item} onChange={e => setItem(Number(`${e.target.value}`))}>
                            {/*<option></option>*/}
                            {
                                props.items.map(item => {
                                    return <option value={item.id}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Cost</h6>
                    <div className="Form-input">
                        <input type="number" placeholder="Item Cost..."defaultValue={cost}
                               onChange={(e) => {
                                   setCost(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Quantity</h6>
                    <div className="Form-input">
                        <input type="number" placeholder="Item Quantity..."defaultValue={quantity}
                               onChange={(e) => {
                                   setQuantity(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!item || !cost || !quantity) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editStoreItem({
                                id: props.editing.id,
                                itemId: item,
                                cost: cost,
                                quantity: quantity,
                            }).then(response => {
                                setSubmitting(false)
                                setItem(-1)
                                setCost(undefined)
                                setQuantity(undefined)
                                setItems(response)
                                onClose()
                            });
                        } else {
                            createStoreItem({
                                itemId: item,
                                cost: cost,
                                quantity: quantity,
                            }).then(response => {
                                setSubmitting(false)
                                setItem(-1)
                                setCost(undefined)
                                setQuantity(undefined)
                                setItems(response)
                                onClose()
                            });
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Item'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function GiveawayModal(props: GiveawayModalProps) {
    const { onClose, open, setGiveaways } = props;
    const classes = useStyles();

    const [name, setName] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [type, setType] = useState<number>(0);
    const [category, setCategory] = useState<string>("Select Category");
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [link, setLink] = useState<string | undefined>(undefined);
    const [end, setEnd] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [editor] = useState(() => withReact(createEditor()))

    const createGiveaway = async (request: CreateGiveawayRequest): Promise<Array<Giveaway>> => axiosPost(`/moderate/create-giveaway`, request)
    const editGiveaway = async (request: EditGiveawayRequest): Promise<Array<Giveaway>> => axiosPut(`/moderate/edit-giveaway`, request)

    useEffect(() => {
        if (props.editing) {
            setName(props.editing.name)
            setImage(props.editing.image)
            setType(props.editing.type)
            setCategory(props.editing.category)
            setDescription(props.editing.description)
            setLink(props.editing.link)
            setEnd(props.editing.end)
        }
    }, [])

    const handleClose = () => {
        onClose();
    };

    const handleTypeChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setType(value)
    };

    const handleCategoryChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setCategory(value)
    };

    const types = [ "Giveaway", "Raffle" ]
    const categories = [ "Select Category", "Shuffle", "RuneScape" ]
    var initialValue: any = [
        {
            type: 'paragraph',
            children: [{ text: props.editing ? props.editing.description : 'Description...' }],
        },
    ]


    const serialize = (nodes: any) => {
        return nodes.map((n: any) => Node.string(n)).join('\n')
    }

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2 className="ModalHeading">Add { type == 0 ? 'Giveaway' : 'Raffle'}</h2>
            <div className="Form-input-field">
                <div className="Form-input ModalSelect">
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Type</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                    root: classes.root,
                                },
                            }}
                            id="demo-multiple-checkbox"
                            value={types[type]}
                            onChange={handleTypeChange}
                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#2a2e38',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#2a2e38',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#2a2e38',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}

                            input={<OutlinedInput sx={{ color: 'white' }} label="Type" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                        >
                            {
                                types.map((variant) => (
                                    //@ts-ignore
                                    <MenuItem key={variant.id} value={variant == 'Raffle' ? 1:0}>
                                        <ListItemText primary={variant} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>{ type == 0 ? 'Giveaway' : 'Raffle'} Name</h6>
                    <div className="Form-input">
                        <input type="text" placeholder={ (type == 0 ? 'Giveaway' : 'Raffle') + " Name..."} defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <div className="Form-input ModalSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Category</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={category}
                                onChange={handleCategoryChange}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }} label="Duration" />}
                                renderValue={(selected) => selected}
                                MenuProps={MenuProps}
                            >
                                {
                                    categories.map((variant) => (
                                        //@ts-ignore
                                        <MenuItem key={variant.id} value={variant}>
                                            <ListItemText primary={variant} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Image URL</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Image..." defaultValue={image}
                               onChange={(e) => {
                                   setImage(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Description</h6>
                    <div className="Form-input">
                        <Slate editor={editor} initialValue={initialValue} onChange={des => {
                            setDescription(serialize(des))
                        }}>
                            <Editable />
                        </Slate>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>{ type == 0 ? 'Giveaway' : 'Raffle'} Link</h6>
                    <div className="Form-input">
                        <input type="text" placeholder={ (type == 0 ? 'Giveaway' : 'Raffle') + " Link..."} defaultValue={link}
                               onChange={(e) => {
                                   setLink(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Giveaway End</h6>
                    <div className="Form-input">
                        <input type="datetime-local" defaultValue={end}
                               onChange={(e) => {
                                   setEnd(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!name || !image || !description || !category || !link || !end) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editGiveaway({
                                id: props.editing.id,
                                type: type,
                                name: name!,
                                image: image!,
                                category: category,
                                description: description!,
                                link: link!,
                                end: end!
                            }).then(r => {
                                setSubmitting(false)
                                handleClose()
                                setGiveaways(r)
                            })
                        } else {
                            createGiveaway({
                                type: type,
                                name: name!,
                                image: image!,
                                category: category,
                                description: description!,
                                link: link,
                                end: end
                            }).then(r => {
                                setSubmitting(false)
                                handleClose()
                                setGiveaways(r)
                            })
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' ' + (type==0?'Giveaway':'Raffle')}</button>
                </div>
            </div>
        </Dialog>
    );
}

function EditBlacklistModal(props: BlacklistModalProps) {
    const { onClose, open, title, endpoint } = props;

    const [text, setText] = useState<string>(props.strings);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const editBlacklist = async (request: BlacklistRequest): Promise<StaticList> => axiosPost(endpoint, request)

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{title}</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>List</h6>
                    <div className="Form-input">
                        <textarea defaultValue={text} onChange={e => setText(e.target.value)} />
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!text) {
                            return
                        }
                        setSubmitting(true)
                        editBlacklist({
                            list: text
                        }).then(r => {
                            window.location.reload()
                        })
                    }}>{submitting ? 'Submitting...' : 'Save'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function AddSlotBattleModal(props: SlotBattleModalProps) {
    const classes = useStyles();
    const { onClose, open, setItems, } = props;

    const [ends, setEnds] = useState<string | undefined>(undefined);
    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<Array<ShuffleSlotsGame>>([]);
    const [selected, setSelected] = useState<Array<ShuffleSlotsGame>>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const games = async (page: number): Promise<Array<ShuffleSlotsGame>> => axiosGet(`/moderate/slot-games?page=${page}`)
    const searchGames = async (name: string, page: number): Promise<Array<ShuffleSlotsGame>> => axiosGet(`/moderate/slot-games-search?name=${name}&page=${page}`)
    const createBattle = async (request: CreateSlotBattleRequest): Promise<Array<SlotBattle>> => axiosPost(`/moderate/create-battle`, request)
    const editBattle = async (request: EditSlotBattleRequest): Promise<Array<SlotBattle>> => axiosPut(`/moderate/edit-battle`, request)

    const handleClose = () => {
        onClose();
    };

    const removeFromSelected = (n: ShuffleSlotsGame) => {
        const alreadyAdded = selected.find(s => s.name == n.name)
        if (!alreadyAdded) {
            return
        }
        setSelected(selected.filter(c => c.name != n.name))
    }
    const addToSelected = (n: ShuffleSlotsGame) => {
        const alreadyAdded = selected.find(s => s.name == n.name)
        if (alreadyAdded) {
            return
        }
        const newSelected = []
        newSelected.push(n)
        selected.map(c => newSelected.push(c))
        setSelected(newSelected)
    };

    useEffect(() => {
        games(1).then(r => setResults(r))
    }, [])

    useEffect(() => {
        if (search) {
            searchGames(search, 0).then(r => setResults(r))
        }
    }, [search])

    useEffect(() => {
        if (props.editing) {
            setEnds(props.editing.votingEnd)
            let selected: Array<ShuffleSlotsGame> = []
            const keys = Object.keys(props.editing.slots)
            if (keys.length > 0) {
                // @ts-ignore
                for (let i = 0; i < keys.length; i++) {
                    const gameData = keys[i].split("::http")
                    selected.push({
                        name: gameData[0],
                        image: 'http' + gameData[1]
                    })
                }
            }
            setSelected(selected)
        }
    }, [])

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Add'} Slot Battle</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Voting Ends In</h6>
                    <div className="Form-input">
                        <input type="datetime-local" defaultValue={ends}
                                   onChange={(e) => {
                                       setEnds(e.target.value)
                                   }}/>
                        </div>
                </div>
                <div className="Form-input-field">
                    <h6>Games</h6>
                    <Grid xs={5} spacing={1}>
                        {
                            selected.map(s => (<Chip onClick={() => removeFromSelected(s)} className={classes.chip}  avatar={<Avatar alt={s.name} src={s.image} />}
                                label={s.name}
                                variant="outlined"
                            />))
                        }
                    </Grid>

                    <div className="Bonus-hunt-search">
                        <div className="search-input">
                            <img src="/navigation/search.svg" width={20} />
                            <input type="text" placeholder="Search" defaultValue={search} onChange={e => setSearch(e.target.value)}/>
                        </div>
                    </div>
                </div>

                <div className="Form-input-field">
                    <div className="Games-search-result">
                        {
                            results.map((r: ShuffleSlotsGame, i: number) => {
                                return (<div className="Result-entry">
                                    <ul onClick={() => {
                                        addToSelected(r)
                                    }}>
                                        <li><span>{i + 1}</span></li>
                                        <li><img src={r.image} width={25} /></li>
                                        <li>
                                            <span>{r.name}</span>
                                        </li>
                                    </ul>
                                </div>)
                            })
                        }
                    </div>
                </div>

                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!ends || selected.length < 2 || selected.length > 8) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editBattle({
                                id: props.editing.id,
                                expiry: ends,
                                games: selected.map(s => s.name)
                            }).then(r => {
                                setEnds(undefined)
                                setSearch('')
                                setResults([])
                                setSelected([])
                                setSubmitting(false)
                                handleClose()
                                setItems(r)
                            })
                        } else {
                            createBattle({
                                expiry: ends,
                                games: selected.map(s => s.name)
                            }).then(r => {
                                setEnds(undefined)
                                setSearch('')
                                setResults([])
                                setSelected([])
                                setSubmitting(false)
                                handleClose()
                                setItems(r)
                            })
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Slot Battle'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function ConcludeSlotBattleModal(props: SlotBattleModalProps) {
    const classes = useStyles();
    const { onClose, open, setItems } = props;

    const [warned, setWarned] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<ShuffleSlotsGame>>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const concludeWinner = async (request: ConcludeSlotBattleRequest): Promise<Array<SlotBattle>> => axiosPut(`/moderate/set-battle-winner`, request)

    const handleClose = () => {
        onClose();
    };

    const removeFromSelected = (n: ShuffleSlotsGame) => {
        const alreadyAdded = selected.find(s => s.name == n.name)
        if (!alreadyAdded) {
            return
        }
        setSelected(selected.filter(c => c.name != n.name))
    }

    const addToSelected = (n: ShuffleSlotsGame) => {
        const alreadyAdded = selected.find(s => s.name == n.name)
        if (alreadyAdded) {
            return
        }
        const newSelected = []
        newSelected.push(n)
        setSelected(newSelected)
    };

    const results: Array<ShuffleSlotsGame> = []
    if (props.editing) {
        Object.keys(props.editing.slots).map(key => {
            const data = key.split('::')
            results.push({
                name: data[0],
                image: data[1],
            })
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>Conclude Slot Battle</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Winning Slot Game</h6>
                    <Grid xs={5} spacing={1}>
                        {
                            selected.map(s => (<Chip onClick={() => removeFromSelected(s)} className={classes.chip}  avatar={<Avatar alt={s.name} src={s.image} />}
                                label={s.name}
                                variant="outlined"
                            />))
                        }
                    </Grid>
                </div>

                <div className="Form-input-field">
                    <div className="Games-search-result">
                        {
                            results!.map((r: ShuffleSlotsGame, i: number) => {
                                return (<div className="Result-entry">
                                    <ul onClick={() => {
                                        addToSelected(r)
                                    }}>
                                        <li><span>{i + 1}</span></li>
                                        <li><img src={r.image} width={25} /></li>
                                        <li>
                                            <span>{r.name}</span>
                                        </li>
                                    </ul>
                                </div>)
                            })
                        }
                    </div>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            name="Warning"
                            checked={warned}
                            onChange={e => setWarned(!warned)}
                        />
                    }
                    label="I understand that this action cannot be undone."/>

                <div className="Form-submission">
                    <button className={warned && selected.length == 1 ? '' : 'Alt'} onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (selected.length != 1 || !warned) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            concludeWinner({
                                id: props.editing.id,
                                game: selected[0].name
                            }).then(r => {
                                setWarned(false)
                                setSelected([])
                                setSubmitting(false)
                                handleClose()
                                setItems(r)
                            })
                        }
                    }}>End Battle & Reward Players</button>
                </div>
            </div>
        </Dialog>
    );
}

const getDetails = async (): Promise<PlayerDetails> => axiosGet(`/player/details`)
const platformStatistics = async (): Promise<PlatformOverview> => axiosGet(`/moderate/stats`)
const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/moderate/pending-transactions`)
const getShufflePlayers = async (): Promise<Array<Player>> => axiosGet(`/moderate/pending-shuffle-verification`)
const getItemDefinitions = async (): Promise<Array<ItemDefinition>> => axiosGet(`/general/item-defs`)

export default function Admin() {

    const ChallengesAdmin = () => {

        const getChallenges = async (): Promise<Array<Challenge>> => axiosGet(`/admin/challenges`)
        const deleteChallenge = async (id: string): Promise<Array<Challenge>> => axiosPut(`/admin/delete-challenge`, {uuid: id})
        const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/admin/players`)
        const getChallengerPrizes = async (): Promise<StaticList> => axiosGet(`/admin/challenger-prizes`)

        const [challenges, setChallenges] = useState<Array<Challenge>>([]);
        const [editing, setEditing] = useState<Challenge | undefined>(undefined);
        const [open, setOpen] = React.useState(false);
        const [openWinner, setOpenWinner] = React.useState(false);
        const [players, setPlayers] = useState<Array<Player>>([]);
        const [prizes, setPrizes] = useState<StaticList>({strings:''});
        const [openPrizes, setOpenPrizes] = React.useState(false);

        useEffect(() => {
            getPlayers().then((response: Array<Player>) => {
                setPlayers(response)
            })
            getChallengerPrizes().then((response: StaticList) => {
                setPrizes(response)
            })
        }, [])

        const handleClose = () => {
            setOpen(false);
            setOpenWinner(false);
            setEditing(undefined);
        };

        useEffect(() => {
            getChallenges().then((response: Array<Challenge>) => {
                setChallenges(response)
            })
        }, [])


        const prizeNames = prizes.strings.split('\n')

        return (<div>

            <div className="Section-heading">
                <h4>Challenges</h4>
                <button onClick={() => setOpen(true)}>Add Challenge</button>
            </div>

            {/* @ts-ignore */}
            <Tabs>
                {/* @ts-ignore */}
                <div label="Challenges">
                    <div className="Btcs-table">
                        <ul className="Header">
                            <li>Name</li>
                            <li>Date</li>
                            <li>Action</li>
                        </ul>
                        {
                            challenges.map(challenge => {
                                return <ul className="Entry">
                                    <li>{challenge.name}</li>
                                    <li>{formatDateYearTime(challenge.creationDate)}</li>
                                    <li className="Actions">

                                        <button onClick={() => {
                                            setEditing(challenge)
                                            setOpen(true)
                                        }}>Edit</button>

                                        { challenge.winnerName ? <div /> : <button onClick={() => {
                                            setEditing(challenge)
                                            setOpenWinner(true)
                                        }}>Set Winner</button> }

                                        <button onClick={() => {
                                            deleteChallenge(challenge.id).then(r => {
                                                setChallenges(r)
                                            })
                                        }}>Delete</button></li>
                                </ul>
                            })
                        }
                    </div>

                </div>
                {/* @ts-ignore */}
                <div label="Prizes">

                    <div className="Section-heading">
                        <button onClick={() => setOpenPrizes(true)}>Edit</button>
                    </div>

                    <div className="Blacklist">
                        <ul>
                            {
                                prizeNames.map(name => <li>{name}</li>)
                            }
                        </ul>
                    </div>

                </div>
            </Tabs>


            <EditBlacklistModal
                key={prizes.strings}
                open={openPrizes}
                onClose={handleClose}
                strings={prizes.strings}
                title={"Edit Challengers Prize List"}
                endpoint={`/admin/modify-challenger-prizes`}
            />

            <AddChallengeModal
                key={editing ? editing.id : 'no-id-challenge'}
                open={open}
                onClose={handleClose}
                editing={editing}
                setItems={setChallenges}
                />

            <ConcludeChallengeModal
                key={editing ? editing.id : 'no-id-challenge'}
                open={openWinner}
                onClose={handleClose}
                editing={editing}
                players={players}
                />
        </div>);
    }

    const HighlightsAdmin = () => {

        const getHighlights = async (): Promise<Array<HighlightClip>> => axiosGet(`/moderate/highlights`)
        const deleteHighlight = async (id: string): Promise<Array<HighlightClip>> => axiosPut(`/moderate/delete-highlight`, {uuid: id})

        const [highlights, setHighlights] = useState<Array<HighlightClip>>([]);
        const [editing, setEditing] = useState<HighlightClip | undefined>(undefined);
        const [open, setOpen] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
            setEditing(undefined);
        };

        useEffect(() => {
            getHighlights().then((response: Array<HighlightClip>) => {
                setHighlights(response)
            })
        }, [])

        return (<div>

            <div className="Section-heading">
                <h4>Highlights</h4>
                <button onClick={() => setOpen(true)}>Add Highlight</button>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Name</li>
                    <li>Date</li>
                    <li>Action</li>
                </ul>
                {
                    highlights.map(highlight => {
                        return <ul className="Entry">
                            <li>{highlight.title}</li>
                            <li>{formatDateYearTime(highlight.creationDate)}</li>
                            <li className="Actions">

                                <button onClick={() => {
                                    setEditing(highlight)
                                    setOpen(true)
                                }}>Edit</button>

                                <button onClick={() => {
                                deleteHighlight(highlight.id).then(r => {
                                    setHighlights(r)
                                })
                            }}>Delete</button></li>
                        </ul>
                    })
                }
            </div>

            <AddHighlightModal
                key={editing ? editing.id : 'no-id-highlight'}
                open={open}
                onClose={handleClose}
                editing={editing}
                setItems={setHighlights}
            />
        </div>);
    }

    const StoreLogs = () => {

        const getLogs = async (): Promise<Array<LogDTO>> => axiosGet(`/admin/store-logs`)

        const [logs, setLogs] = useState<Array<LogDTO>>([]);
        const [search, setSearch] = useState<string | undefined>(undefined);

        useEffect(() => {
            getLogs().then((response: Array<LogDTO>) => {
                setLogs(response)
            })
        }, [])

        return (<div>
            <div className="Section-heading">
                <h4>Store Logs</h4>
            </div>
            <div className="Bonus-hunt-search">
                <div className="search-input">
                    <img src="/navigation/search.svg" width={20} />
                    <input type="text" placeholder="Search" defaultValue={search} onChange={e => setSearch(e.target.value)}/>
                </div>
            </div>
            <div className="logs">
                {
                    logs
                        .filter(log => !search || log.message.toLowerCase().includes(search.toLowerCase()))
                        .map(log => {
                        return (<ul>
                            <li>{formatDateYearTime(log.date)}</li>
                            <li>{log.message}</li>
                        </ul>)
                    })
                }
            </div>
        </div>);
    }

    const StoreAdmin = (itemDefs: Array<ItemDefinition>) => {

        const getItems = async (): Promise<Array<StoreItem>> => axiosGet(`/admin/store-items`)
        const increaseItemQty = async (id: string): Promise<Array<StoreItem>> => axiosPut(`/admin/increase-quantity`, {uuid: id})
        const decreaseItemQty = async (id: string): Promise<Array<StoreItem>> => axiosPut(`/admin/decrease-quantity`, {uuid: id})
        const deleteStoreItem = async (id: string): Promise<Array<StoreItem>> => axiosPut(`/admin/delete-store-item`, {uuid: id})

        const [items, setItems] = useState<Array<StoreItem>>([]);
        const [editing, setEditing] = useState<StoreItem | undefined>(undefined);
        const [open, setOpen] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
            setEditing(undefined);
        };

        useEffect(() => {
            getItems().then((response: Array<StoreItem>) => {
                setItems(response)
            })
        }, [])

        return (<div>

            <div className="Section-heading">
                <h4>Store Items</h4>
                <button onClick={() => setOpen(true)}>Add Item</button>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Name</li>
                    <li>Price</li>
                    <li>Quantity</li>
                    <li>Action</li>
                </ul>
                {
                    items.map(item => {
                        return <ul className="Entry">
                            <li>{item.definition.name}</li>
                            <li>{item.cost.toLocaleString('en-US')}</li>
                            <li>{item.quantity.toLocaleString('en-US')}</li>
                            <li className="Actions">

                                <button onClick={() => {
                                    setEditing(item)
                                    setOpen(true)
                                }}>Edit</button>

                                <button onClick={() => {
                                    deleteStoreItem(item.id).then(r => {
                                        setItems(r)
                                    })
                                }}>Delete</button>

                                <button onClick={() => {
                                    increaseItemQty(item.id).then(r => {
                                        setItems(r)
                                    })
                                }}>+ Qty.</button>

                                <button onClick={() => {
                                    decreaseItemQty(item.id).then(r => {
                                        setItems(r)
                                    })
                                }}>- Qty.</button>
                            </li>
                        </ul>
                    })
                }
            </div>

            <AddStoreItemModal
                key={editing ? editing.id : 'no-id-store-item'}
                open={open}
                onClose={handleClose}
                editing={editing}
                items={itemDefs}
                setItems={setItems}
            />
        </div>);
    }

    const ItemsAdmin = (items: Array<ItemDefinition>, setItems: any) => {

        const deleteItem = async (id: number): Promise<Array<ItemDefinition>> => axiosPut(`/admin/delete-item?id=${id}`, {})
        const [editing, setEditing] = useState<ItemDefinition | undefined>(undefined);
        const [open, setOpen] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
            setEditing(undefined);
        };

        return (<div>

            <div className="Section-heading">
                <h4>Item Definitions</h4>
                <button onClick={() => setOpen(true)}>Add Item Defs</button>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Name</li>
                    <li>Action</li>
                </ul>
                {
                    items.map(item => {
                        return <ul className="Entry">
                            <li>{item.name}</li>
                            <li className="Actions">

                                <button onClick={() => {
                                    setEditing(item)
                                    setOpen(true)
                                }}>Edit</button>

                                <button onClick={() => {
                                deleteItem(item.id).then(r => {
                                    setItems(r)
                                })
                            }}>Delete</button>
                            </li>
                        </ul>
                    })
                }
            </div>

            <AddItemModal
                open={open}
                key={editing ? editing.id : 'no-id-item'}
                onClose={handleClose}
                editing={editing}
                items={items}
                setItems={setItems}
            />
        </div>);
    }

    const PlayerLogs = (logs: Array<LogPlayer>) => {
        return <div className="logs">
            {
                logs.map(log => {
                    return (<ul>
                        <li>{formatDateYearTime(log.date)}</li>
                        <li>{log.message}</li>
                    </ul>)
                })
            }
        </div>
    }

    const PlayersAdmin = (defs: Array<ItemDefinition>) => {
        const classes = useStyles();

        const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/admin/players`)
        const [players, setPlayers] = useState<Array<Player>>([]);
        const [search, setSearch] = useState<string | undefined>(undefined);

        useEffect(() => {
            getPlayers().then((response: Array<Player>) => {
                setPlayers(response)
            })
        }, [])

        return (<div>

            <div className="Section-heading">
                <h4>Players</h4>
            </div>

            <div className="Bonus-hunt-search">
                <div className="search-input">
                    <img src="/navigation/search.svg" width={20} />
                    <input type="text" placeholder="Search" defaultValue={search} onChange={e => setSearch(e.target.value)}/>
                </div>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Name</li>
                    <li>Rank</li>
                    <li>Last Online</li>
                </ul>
                {
                    players.filter(player => {
                        return !search || player.username.toLowerCase().includes(search.toLowerCase())
                    }).map(player => {
                        return <ul className="Entry" onClick={() => {
                            window.location.href = `https://btcs.gg/admin/users/${player.id}`
                        }}>
                            <li>{player.username}</li>
                            <li>{player.authority}</li>
                            <li>{formatDateYearTime(player.lastOnline)}</li>
                        </ul>
                    })
                }
            </div>

        </div>);
    }

    const PlayerViewAdmin = (defs: Array<ItemDefinition>, name: string) => {
        const classes = useStyles();

        const getPlayer = async (id: string): Promise<Player> => axiosPost(`/admin/player`, {uuid: id})
        const modifyPoints = async (id: string, increase: boolean, points: number): Promise<Player> => axiosPost(`/admin/modify-points`, {uuid: id, increase: increase, points: points})
        const modifyPrizeUsd = async (id: string, increase: boolean, points: number): Promise<Player> => axiosPost(`/admin/modify-prize`, {uuid: id, increase: increase, points: points})
        const verifyShuffle = async (id: string): Promise<Array<Giveaway>> => axiosPut(`/moderate/verify-shuffle`, {uuid: id})
        const resetShuffle = async (id: string): Promise<Array<Giveaway>> => axiosPut(`/moderate/reset-shuffle`, {uuid: id})

        const addItem = async (request: PlayerAddItemRequest): Promise<Player> => axiosPost(`/admin/add-item`, request)
        const moveItem = async (request: PlayerMoveItemRequest): Promise<Player> => axiosPost(`/admin/move-item`, request)

        const [adding, setAdding] = useState<number>(0);
        const [addingPrize, setAddingPrize] = useState<number>(0);
        const [addingItem, setAddingItem] = useState<boolean>(false);
        const [movingItem, setMovingItem] = useState<boolean>(false);
        const [addingInventory, setAddingInventory] = useState<Item | undefined>(undefined);
        const [points, setPoints] = useState<number>(0);
        const [prize, setPrize] = useState<number>(0);
        const [player, setPlayer] = useState<Player | undefined>(undefined);
        const [selectedItems, setSelectedItems] = useState<Array<ObtainedItem>>([]);

        const setSelectedItem = (item: ObtainedItem) => {
            const exists = selectedItems.find(i2 => i2.id == item.id)
            const newItems: Array<ObtainedItem> = []
            selectedItems.map(i2 => {
                if (exists) {
                    if (item.id != i2.id) {
                        newItems.push(i2)
                    }
                } else {
                    newItems.push(i2)
                }
            })
            if (!exists) {
                newItems.push(item)
            }
            setSelectedItems(newItems)
        }

        useEffect(() => {
            getPlayer(name).then(response => {
                setPlayer(response)
            })
        }, [])

        const getBreakdown = (times: Array<WatchTime>) => {
            return (<div className="Breakdowns">
                {
                    times.map(t => {
                        return (<div className="Breakdown">
                            <div className="Breakdown-title">
                                {t.channelName}
                            </div>
                            <div className="Viewership">
                                <ul>
                                    <li className="Platform">
                                        <div className="Platform">Kick</div>
                                        <div className="Time">{moment.utc(t.kickTime).format('HH:mm:ss')}</div>
                                    </li>
                                    <li className="Platform">
                                        <div className="Platform">Twitch</div>
                                        <div className="Time">{moment.utc(t.twitchTime).format('HH:mm:ss')}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>)
                    })
                }
            </div>)
        }

        if (player) {
            return (<div>
                <div className="Inspect-return" onClick={() => {
                    window.location.href = 'https://btcs.gg/admin/users'
                }}>
                    <ArrowBack />
                    <span>Back</span>
                </div>
                <div className="Section-heading">
                    <h4>{player.username}</h4>
                </div>
                <br/><br/><br/>
                <div className="Viewing-player">
                    <div className="Player-overview">
                        <ul>
                            <li>Username</li><li>{player.username}</li>
                        </ul>
                        <ul>
                            <li>Rank</li><li>{player.authority}</li>
                        </ul>
                        <ul>
                            <li>Balance</li><li><div className="Balance">
                            <img src="/currency.svg" />
                            <div  className="Points-value">{player.points.toLocaleString('en-US')}</div>
                        </div></li>
                        </ul>
                        <ul>
                            <li>Leaderboard Winnings</li><li>${player.leaderboardPrizes.toLocaleString('en-US')}</li>
                        </ul>
                        <ul>
                            <li>Joined</li><li>{formatDateYearTime(player.joined)}</li>
                        </ul>
                        <ul>
                            <li>Last Online</li><li>{formatDateYearTime(player.lastOnline)}</li>
                        </ul>
                        <ul>
                            <li>BTC Wallet</li><li>{player.btcWallet}</li>
                        </ul>
                        <ul>
                            <li>ETH Wallet</li><li>{player.ethWallet}</li>
                        </ul>
                        <ul>
                            <li>Discord</li><li>{player.discordName}</li>
                        </ul>
                        <ul>
                            <li>Kick Username</li><li>{player.kickName}</li>
                        </ul>
                        <ul>
                            <li>Shuffle Username</li><li>{player.shuffleName} {player.shuffleNameApproved ?

                            <button
                                onClick={() => {
                                    resetShuffle(player.id).then(r => {
                                        window.location.reload()
                                    })
                                }}
                            >Reset</button> : <>
                            <button
                                onClick={() => {
                                    verifyShuffle(player.id).then(r => {
                                        window.location.reload()
                                    })
                                }}
                            >Approve</button>
                        </> } </li>
                        </ul>
                        <ul>
                            <li>Watch Time</li><li>{moment.utc(player.watchTime.overall).format('HH:mm:ss')}</li>
                        </ul>
                        <ul>
                            {player.watchTime ?(<><li>Breakdown</li><li>{getBreakdown(player.watchTime.times)}</li></>)
                                : <></>}
                        </ul>
                        <br /><br /><br /><br />
                        {
                            adding !== 0 ? <div>
                                <span>How many points would you like to {adding == -1 ? 'remove' : 'add'}?</span>
                                <div className="Btcs-form">
                                    <div className="Form-input-field">
                                        <h6>Points</h6>
                                        <div className="Form-input">
                                            <div className="Form-icon">
                                                <img src="/currency.svg"/>
                                            </div>
                                            <input type="number" placeholder="Points" defaultValue={points}
                                                   onChange={(e) => {
                                                       setPoints(Number(e.target.value))
                                                   }}/>
                                            <h5 onClick={() => {
                                                if (points > 0) {
                                                    const increase = adding === 1
                                                    setAdding(3)
                                                    modifyPoints(player.id, increase, points).then(r => {
                                                        setPlayer(r)
                                                        setAdding(0)
                                                    })
                                                }
                                            }}>Update</h5>
                                        </div>
                                    </div>
                                </div>

                            </div> : <ul>
                                <li><button onClick={() => setAdding(1)}>Add Points</button></li>
                                <li><button onClick={() => setAdding(-1)}>Remove Points</button></li>
                                <li></li>
                            </ul>
                        }
                        {
                            addingPrize !== 0 ? <div>
                                <span>How many Leaderboard Prize Dollars would you like to {addingPrize == -1 ? 'remove' : 'add'}?</span>
                                <div className="Btcs-form">
                                    <div className="Form-input-field">
                                        <h6>Prize ($)</h6>
                                        <div className="Form-input">
                                            <div className="Form-icon">
                                                <img src="/currency.svg"/>
                                            </div>
                                            <input type="number" placeholder="Prize ($)" defaultValue={prize}
                                                   onChange={(e) => {
                                                       setPrize(Number(e.target.value))
                                                   }}/>
                                            <h5 onClick={() => {
                                                if (prize > 0) {
                                                    const increase = addingPrize === 1
                                                    setAddingPrize(3)
                                                    modifyPrizeUsd(player.id, increase, prize).then(r => {
                                                        setPlayer(r)
                                                        setAddingPrize(0)
                                                    })
                                                }
                                            }}>Update</h5>
                                        </div>
                                    </div>
                                </div>

                            </div> : <ul>
                                <li><button onClick={() => setAddingPrize(1)}>Add Prize</button></li>
                                <li><button onClick={() => setAddingPrize(-1)}>Remove Prize</button></li>
                                <li></li>
                            </ul>
                        }
                        <br /><br /><br /><br />
                    </div>
                    <div className="Player-logs">
                        {/* @ts-ignore */}
                        <Tabs>
                            {/* @ts-ignore */}
                            <div label="Pending Items">
                                <div className="Inventory-actions">
                                    { Items(classes, defs, player.items.filter(i => i.status == 'PENDING'), selectedItems, setSelectedItem) }
                                    { selectedItems.length > 0 ? <ul>
                                        <li><span>With Selected:</span></li>
                                        <li><button onClick={() => {
                                            if (!movingItem && selectedItems.length > 0) {
                                                setMovingItem(true)
                                                selectedItems.map(selected => {
                                                    moveItem({
                                                        uuid: player.id,
                                                        id: selected.item,
                                                        quantity: selected.quantity,
                                                        cancel: false
                                                    }).then(r => {
                                                        setAddingInventory(undefined)
                                                        setPlayer(r)
                                                    })
                                                })
                                                setMovingItem(false)
                                            }
                                        }}>{movingItem ? 'Marking...' : 'Mark As Received'}</button></li>
                                        <li><button onClick={() => {
                                            if (!movingItem && selectedItems.length > 0) {
                                                setMovingItem(true)
                                                selectedItems.map(selected => {
                                                    moveItem({
                                                        uuid: player.id,
                                                        id: selected.item,
                                                        quantity: selected.quantity,
                                                        cancel: true
                                                    }).then(r => {
                                                        setAddingInventory(undefined)
                                                        setPlayer(r)
                                                    })
                                                })
                                                setMovingItem(false)
                                            }
                                        }}>{movingItem ? 'Marking...' : 'Mark As Cancelled'}</button></li>
                                    </ul> : <></> }
                                    { addingInventory ? <ul>
                                        <li>
                                            <select defaultValue={addingInventory.id} onChange={e => setAddingInventory({id: Number(e.target.value), quantity: addingInventory.quantity})}>
                                                <option value={-1}>Select Item</option>
                                                {
                                                    defs.map(item => <option value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                        </li>
                                        <li><input type="number" defaultValue={addingInventory.quantity} /></li>
                                        <li><button className={ addingInventory.id > 0 && addingInventory.quantity > 0 ? '' : 'Alt' } onClick={() => {
                                            if (!addingItem && addingInventory.id > 0 && addingInventory.quantity) {
                                                setAddingItem(true)
                                                addItem({
                                                    uuid: player.id,
                                                    id: addingInventory.id,
                                                    quantity: addingInventory.quantity
                                                }).then(r => {
                                                    setAddingItem(false)
                                                    setAddingInventory(undefined)
                                                    setPlayer(r)
                                                })
                                            }
                                        }}>{addingItem ? 'Confirming...' : 'Confirm Item'}</button></li>
                                        <li><button onClick={() => setAddingInventory(undefined)}>Cancel Item</button></li>

                                    </ul> : <ul>
                                        <li><button onClick={() => setAddingInventory({
                                            id: -1, quantity: 1
                                        })}>Add Item</button></li>
                                    </ul> }
                                </div>
                            </div>
                            {/* @ts-ignore */}
                            <div label="All Items">
                                { Items(classes, defs, player.items) }
                            </div>
                            {/* @ts-ignore */}
                            <div label="Logs">
                                {
                                    PlayerLogs(player.logs)
                                }
                            </div>
                            {/* @ts-ignore */}
                            <div label="Alt Accounts">
                            </div>
                        </Tabs>
                    </div>
                </div>

            </div>);
        }
        return (<div>

            <div className="Section-heading">
                <h4>Loading...</h4>
            </div>

        </div>);
    }

    const GiveawayAdmin = () => {

        const getGiveaways = async (): Promise<Array<Giveaway>> => axiosGet(`/moderate/giveaways`)
        const deleteGiveaway = async (id: string): Promise<Array<Giveaway>> => axiosPut(`/moderate/delete-giveaway`, {uuid: id})

        const [editing, setEditing] = useState<Giveaway | undefined>(undefined);
        const [giveaways, setGiveaways] = useState<Array<Giveaway>>([]);
        const [open, setOpen] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
            setEditing(undefined);
        };

        useEffect(() => {
            getGiveaways().then((response: Array<Giveaway>) => {
                setGiveaways(response)
            })
        }, [])

        return (<div>
            <div className="Section-heading">
                <h4>Giveaways & Raffles</h4>
                <button onClick={() => setOpen(true)}>Add</button>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Name</li>
                    <li>Type</li>
                    <li>Action</li>
                </ul>
                {
                    giveaways.map(item => {
                        return <ul className="Entry">
                            <li>{item.name}</li>
                            <li>{item.type == 0 ? 'Giveaway' : 'Raffle'}</li>
                            <li className="Actions">
                                <button onClick={() => {
                                    setEditing(item)
                                    setOpen(true)
                                }}>Edit</button>
                                <button onClick={() => {
                                    deleteGiveaway(item.id).then(r => {
                                        setGiveaways(r)
                                    })
                            }}>Delete</button>
                            </li>
                        </ul>
                    })
                }
            </div>

            <GiveawayModal
                open={open}
                key={editing ? editing.id : 'no-id-giveaway'}
                onClose={handleClose}
                editing={editing}
                setGiveaways={setGiveaways}
            />

        </div>);
    }

    const LeaderboardAdmin = () => {

        const getBlacklist = async (): Promise<StaticList> => axiosGet(`/admin/blacklist`)
        const getLeaderboardPrizes = async (): Promise<StaticList> => axiosGet(`/admin/leaderboard-prizes`)
        const getSnapshotsList = async (): Promise<Array<string>> => axiosGet(`/moderate/leaderboard-snapshots`)
        const uploadLeaderboardData = async (file: any): Promise<boolean> => postFile(backend, `/moderate/leaderboard-data`, file)
        const downloadSnapshot = async (file: string): Promise<Blob | MediaSource> => axiosGetBlob(`/moderate/download-snapshot/${file}`)
        const downloadLatestSnapshot = async (): Promise<Blob | MediaSource> => axiosGetBlob(`/moderate/download-snapshot/latest`)

        const [blacklist, setBlacklist] = useState<StaticList>({strings:''});
        const [prizes, setPrizes] = useState<StaticList>({strings:''});
        const [snapshots, setSnapshots] = useState<Array<string>>([]);
        const [selectedSnapshot, setSelectedSnapshot] = useState<string>("-1");
        const [open, setOpen] = React.useState(false);
        const [selectedFile, setSelectedFile] = React.useState<any>(undefined);
        const [openPrizes, setOpenPrizes] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
        };

        const handleClosePrizes = () => {
            setOpenPrizes(false);
        };

        useEffect(() => {
            getBlacklist().then((response: StaticList) => {
                setBlacklist(response)
            })
            getLeaderboardPrizes().then((response: StaticList) => {
                setPrizes(response)
            })
            getSnapshotsList().then((response: Array<string>) => {
                setSnapshots(response)
            })
        }, [])
        const names = blacklist.strings.split('\n')
        const prizeNames = prizes.strings.split('\n')

        return (<div>
            <div className="Section-heading">
                <h4>Leaderboard</h4>
            </div>
            <br/><br/><br/>
            <div className="Leaderboard-admin">
                <div className="Leaderboards-overview">
                    <ul>
                        <li>
                            <form id="uploadbanner" encType="multipart/form-data">
                                <input id="fileupload" name="leaderboard_data" type="file"
                                       onChange={(event: any) => setSelectedFile(event.target.files[0])} />
                            </form>
                        </li>
                        <li><button onClick={() => {
                            if (!selectedFile) return
                            console.log(selectedFile)

                            // const uploadFile = (file: any) => {
                                let formData = new FormData();
                                formData.append("file", selectedFile);
                            //     axios.post("https://api.btcs.gg/moderate/leaderboard-data", formData, {
                            //         headers: {
                            //             "Content-Type": "multipart/form-data",
                            //         },
                            //     }).then((response) => {
                            //         // fnSuccess(response);
                            //         console.log("success")
                            //         console.log(response)
                            //     }).catch((error) => {
                            //         // fnFail(error);
                            //         console.log("fail")
                            //         console.log(error)
                            //     });
                            // };
                            // uploadFile(selectedFile);

                            uploadLeaderboardData(formData).then(r => {
                                console.log(r)
                            })
                        }}>Upload Leaderboard Data</button></li>
                    </ul>
                    <ul>
                        <li>
                            <select defaultValue={selectedSnapshot} onChange={e => setSelectedSnapshot(e.target.value)}>
                                <option value="-1">Select Snapshot</option>
                                {
                                    snapshots.map(s => <option value={s}>{s}</option>)
                                }
                            </select>
                        </li>
                        <li><button className={selectedSnapshot == '-1' ? 'Alt' : ''} onClick={() => {
                            if (selectedSnapshot != '-1') {
                                downloadSnapshot(selectedSnapshot).then(blob => {
                                    const href = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.href = href;
                                    link.setAttribute('download', 'btcs_leaderboard_snapshot.csv'); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                            }
                        }}>Download Snapshot</button></li>
                    </ul>
                    <ul>
                        <li><button onClick={() => {
                            downloadLatestSnapshot().then(blob => {
                                const href = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', 'btcs_leaderboard_snapshot.csv'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                        }}>Download Current Leaderboard Data</button></li>
                    </ul>
                </div>
                <div className="Leaderboard-blacklist">

                    {/* @ts-ignore */}
                    <Tabs>
                        {/* @ts-ignore */}
                        <div label="Prizes">

                            <div className="Section-heading">
                                <h4>Prizes</h4>
                                <button onClick={() => setOpenPrizes(true)}>Edit</button>
                            </div>

                            <div className="Blacklist">
                                <ul>
                                    {
                                        prizeNames.map(name => <li>{name}</li>)
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* @ts-ignore */}
                        <div label="Blacklist">

                            <div className="Section-heading">
                                <h4>Blacklist</h4>
                                <button onClick={() => setOpen(true)}>Edit</button>
                            </div>

                            <div className="Blacklist">
                                <ul>
                                    {
                                        names.map(name => <li>{name}</li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>

            <EditBlacklistModal
                key={prizes.strings}
                open={openPrizes}
                onClose={handleClosePrizes}
                strings={prizes.strings}
                title={"Edit Leaderboard Prize List"}
                endpoint={`/admin/modify-prizes`}
            />

            <EditBlacklistModal
                key={blacklist.strings}
                open={open}
                onClose={handleClose}
                strings={blacklist.strings}
                title={"Edit Leaderboard Blacklist"}
                endpoint={`/admin/modify-blacklist`}
            />

        </div>);
    }

    const SlotBattlesAdmin = () => {

        const getBattles = async (): Promise<Array<SlotBattle>> => axiosGet(`/general/slot-battles`)
        const deleteBattle = async (id: string): Promise<Array<SlotBattle>> => axiosPut(`/moderate/delete-battle`, {uuid: id})

        const [battles, setBattles] = useState<Array<SlotBattle>>([]);
        const [editing, setEditing] = useState<SlotBattle | undefined>(undefined);
        const [open, setOpen] = React.useState(false);
        const [openConclude, setOpenConclude] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
            setEditing(undefined);
        };

        const handleCloseConclude = () => {
            setOpenConclude(false);
            setEditing(undefined);
        };

        useEffect(() => {
            getBattles().then((response: Array<SlotBattle>) => {
                setBattles(response)
            })
        }, [])

        return (<div>

            <div className="Section-heading">
                <h4>Slot Battles</h4>
                <button onClick={() => setOpen(true)}>New Battle</button>
            </div>

            <div className="Btcs-table">
                <ul className="Header">
                    <li>Streamer</li>
                    <li>Date</li>
                    <li>Action</li>
                </ul>
                {
                    battles.map(battle => {
                        return <ul className="Entry">
                            <li>{battle.streamer}</li>
                            <li>{formatDateYearTime(battle.creationDate)}</li>
                            <li className="Actions">

                                { battle.winner ? <></> : <button onClick={() => {
                                    setEditing(battle)
                                    setOpenConclude(true)
                                }}>Conclude</button> }

                                <button onClick={() => {
                                    setEditing(battle)
                                    setOpen(true)
                                }}>Edit</button>

                                <button onClick={() => {
                                    deleteBattle(battle.id).then(r => {
                                        setBattles(r)
                                    })
                                }}>Delete</button></li>
                        </ul>
                    })
                }
            </div>

            <AddSlotBattleModal
                key={editing ? editing.id : 'no-id-slotbattle'}
                open={open}
                onClose={handleClose}
                editing={editing}
                setItems={setBattles}
            />

            <ConcludeSlotBattleModal
                key={editing ? editing.id : 'no-id-concludeslotbattle'}
                open={openConclude}
                onClose={handleCloseConclude}
                editing={editing}
                setItems={setBattles}
            />
        </div>);
    }

    const [details, setDetails] = useState<PlayerDetails | undefined>(undefined);
    const [stats, setStats] = useState<PlatformOverview | undefined>(undefined);
    const [players, setPlayers] = useState<Array<Player>>([]);
    const [playersShuffle, setPlayersShuffle] = useState<Array<Player>>([]);
    const [items, setItems] = useState<Array<ItemDefinition>>([]);

    const params = useParams();
    const page = params.page
    const model = params.model

    if (!page) {
        window.location.href = '/admin/dashboard'
    }

    useEffect(() => {
        getDetails().then(details => {
            if (details.authority == 'USER') {
                window.location.href = "/"
            }
            getItemDefinitions().then(response => {
                setDetails(details)
                setItems(response)
            })
            getPlayers().then((response: Array<Player>) => {
                setPlayers(response)
            })
            getShufflePlayers().then((response: Array<Player>) => {
                setPlayersShuffle(response)
            })
            platformStatistics().then(response => {
                setStats(response)
            })
        }).catch(e => window.location.href = "/")

    }, [])


    switch (page) {
        case 'dashboard': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={0} />
                <div className="App-contents Dashboard">
                    <div className="Dashboard-Metrics">
                        <div className="MetricCard">
                            <h6>{stats ? stats.signupsToday : '-'}</h6>
                            <h3>Signups Today</h3>
                        </div>
                        <div className="MetricCard">
                            <h6>{stats ? stats.signupsWeek : '-'}</h6>
                            <h3>Signups This Week</h3>
                        </div>
                        <div className="MetricCard">
                            <h6>{stats ? stats.signupsMonth : '-'}</h6>
                            <h3>Signups This Month</h3>
                        </div>
                        <div className="MetricCard">
                            <h6>{stats ? stats.rewardsClaimed : '-'}</h6>
                            <h3>Rewards Claimed</h3>
                        </div>
                        <div className="MetricCard">
                            <h6>{stats ? stats.casesOpened : '-'}</h6>
                            <h3>Cases Opened</h3>
                        </div>

                        <a href="https://stats.jcy.gg/share/dyeEJdk57xpziI3c/BTCS.GG" target="_action"><button>Stats</button></a>
                    </div>
                    <div className="Dashboard-Pending">
                        <span>Pending Transactions</span>

                        <div className="Btcs-table" style={{width: '100%'}}>
                            <ul className="Header">
                                <li>Name</li>
                                <li>Rank</li>
                                <li>Last Online</li>
                            </ul>
                            {
                                players.map(player => {
                                    return <ul className="Entry" onClick={() => {
                                        window.location.href = `https://btcs.gg/admin/users/${player.id}`
                                    }}>
                                        <li>{player.username}</li>
                                        <li>{player.authority}</li>
                                        <li>{formatDateYearTime(player.lastOnline)}</li>
                                    </ul>
                                })
                            }
                        </div>
                    </div>
                    <div className="Dashboard-Pending">
                        <span>Pending Shuffle Username Verification</span>

                        <div className="Btcs-table" style={{width: '100%'}}>
                            <ul className="Header">
                                <li>Name</li>
                                <li>Rank</li>
                                <li>Last Online</li>
                            </ul>
                            {
                                playersShuffle.map(player => {
                                    return <ul className="Entry" onClick={() => {
                                        window.location.href = `https://btcs.gg/admin/users/${player.id}`
                                    }}>
                                        <li>{player.username}</li>
                                        <li>{player.authority}</li>
                                        <li>{formatDateYearTime(player.lastOnline)}</li>
                                    </ul>
                                })
                            }
                        </div>
                    </div>
                </div>
            </>)
        }
        case 'challenges': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={1} />
                <div className="App-contents">
                    {ChallengesAdmin()}
                </div>
            </>)
        }
        case 'highlights': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={2} />
                <div className="App-contents">
                    {HighlightsAdmin()}
                </div>
            </>)
        }
        case 'items': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={3} />
                <div className="App-contents">
                    {ItemsAdmin(items, setItems)}
                </div>
            </>)
        }
        case 'store': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={4} />
                <div className="App-contents">
                    {StoreAdmin(items)}
                </div>
            </>)
        }
        case 'giveaways': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={7} />
                <div className="App-contents">
                    {GiveawayAdmin()}
                </div>
            </>)
        }
        case 'store-log': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={5} />
                <div className="App-contents">
                    {StoreLogs()}
                </div>
            </>)
        }
        case 'battles': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={6} />
                <div className="App-contents">
                    {SlotBattlesAdmin()}
                </div>
            </>)
        }
        case 'users': {
            if (model) {
                return (<>
                    <AdminNavigation authority={details ? details.authority : 'USER'} active={8} />
                    <div className="App-contents">
                        {PlayerViewAdmin(items, model)}
                    </div>
                </>)
            }
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={8} />
                <div className="App-contents">
                    {PlayersAdmin(items)}
                </div>
            </>)
        }
        case 'leaderboard': {
            return (<>
                <AdminNavigation authority={details ? details.authority : 'USER'} active={9} />
                <div className="App-contents">
                    {LeaderboardAdmin()}
                </div>
            </>)
        }
    }
    return (<>
        <AdminNavigation authority={details ? details.authority : 'USER'}  active={0} />
        <div className="App-contents">
            {/*{params}*/}
        </div>
    </>)
}

const Items = (classes: any, defs: Array<ItemDefinition>, inventory?: Array<ObtainedItem>, selected?: Array<ObtainedItem>, setSelectedItem?: any) => {
    const map = new Map<number, ItemDefinition>()
    defs.map(def => map.set(def.id, def))
    return (<Grid className={classes.gridList} spacing={0} container>
        {inventory!.map((tile) => {

            const currentlySelected = selected && selected.find(i2 => i2.id == tile.id)
                return (<Card sx={{minWidth: 100, maxWidth: 100, minHeight: 100, maxHeight: 100}} onClick={() => {
                    if (setSelectedItem) {
                        setSelectedItem(tile)
                    }
                }} className={classes.card}>
                    <CardContent className={currentlySelected ? classes.chipSelected : classes.cardContent}
                                 style={{backgroundImage: `url(${defs.find(d => d.id == tile.item)?.image})`}}>
                        <span>
                            {tile.quantity}x
                        </span>
                    </CardContent>
                </Card>)
            }
        )}
    </Grid>)
}