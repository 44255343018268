import {Footer} from "../Components/Footer";
import React from "react";
import {Navigation} from "../Components/Navigation";

export default function Cases() {
    return (<>
        <div className="App-contents Challenges">
            <div className="Content-container">
                <h2>Cases</h2>
                <p style={{display: 'flex', justifyContent: 'center'}}>You have <div className="Balance">
                    <img src="/currency.svg" />
                    <div  className="Points-value">{0}</div>
                </div> Points</p>
            </div>
            <div className="Section-heading">
                <h4>Cases</h4>
            </div>
            <br /> <br />

            <Footer/>
        </div>
    </>)
}