import React, {useEffect, useState} from 'react';
import '../Assets/CSS/App.scss';

import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {FeaturedVideo, HighlightsApi} from "../API/api";
import {axiosGet} from "../Utility/httpClient";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto",
        display: 'inline-flex',
        flexDirection: 'row',
    },
    card: {
        height: "280px",
        maxWidth: "445px",
        minWidth: "445px",
        transition: ".6s !important",
        backgroundColor: 'transparent !important',
        padding: '0 !important',
        margin: '0 !important',
        boxShadow: 'none !important',
        border: 'none',
        borderRadius: '15px !important',
    },
    cardMedia: {
        height: '220px',
        borderRadius: '15px',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important',
    },
    cardContent: {
        textAlign: 'left',
        fontSize: '11px !important',
        boxShadow: 'none',
        padding: '0 !important',
        color: '#cecece'

    },
}));

export type VideosProps = {
};

const getHighlights = async (): Promise<HighlightsApi> => axiosGet(`/general/highlight-clips`)

export const StreamersVideos: React.FC<VideosProps> = ({}) => {
    const classes = useStyles();

    const [data, setData] = useState<HighlightsApi | undefined>(undefined);

    useEffect(() => {
        getHighlights().then(response => {
            setData(response)
        })
    }, [])
    const ordered = [ "BTCs", "Lance", "Hunterowner", "GrayGray" ]
    return (
        <>
            {
                data ?
                    ordered.map(key => {
                        const streamer = data!.streamersFeaturedVideos.find(tile => tile.streamer == key)!
                        return (
                            <>
                                <p className="StreamerName">{streamer.streamer}</p>
                                <div className="Infinite-horizontal Streamer-videos">
                                    <div className={classes.gridList + ' Infinite-horizontal'}>
                                        {streamer.videos.map((tile) => {
                                            return (<a target="_action" href={tile.url} className={"Video-case CardHover-video"}>
                                                <Card className={classes.card + ' Card'}>
                                                    <CardMedia
                                                        component="img"
                                                        alt={tile.title}
                                                        className={classes.cardMedia}
                                                        height="140"
                                                        image={tile.thumbnail}
                                                    />
                                                    <CardContent className={classes.cardContent}>
                                                        <div className="Video-Details">
                                                            <div className="Video-Uploader">
                                                                <img src={tile.uploaderImage}/>
                                                            </div>
                                                            <div className="Video-Title">
                                                                <span>{tile.title}</span>
                                                                <p>{tile.uploader}</p>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </a>)
                                        })
                                        }
                                    </div>
                                </div>
                            </>)
                    }) : <></>
            }
        </>
    );
}

