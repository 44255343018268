import Streamers from "../Components/Streamers";
import {Videos} from "../Components/Videos";
import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {MenuProps} from "./Hunts";
import {LeaderboardEntry, PlayerDetails} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import {SlotBattlesProps} from "./SlotBattles";
import {StreamersVideos} from "../Components/StreamersVideos";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

const getLeaderboard = async (): Promise<Array<LeaderboardEntry>> => axiosGet(`/general/top-3-leaderboard`);

export const Landing: React.FC<LandingProps> = ({userDetails}) => {
    const classes = useStyles();
    const [highlightFilter, setHighlightsFilter] = useState<string>('1st Place');
    const [data, setData] = useState<Array<LeaderboardEntry>>([]);

    useEffect(() => {
        getLeaderboard().then(response => {
            setData(response)
        })
    }, [])

    const handleChange = (event: any) => {
        const {
            target: {value},
        } = event;
        setHighlightsFilter(value)
    };

    function format(v: number) {
        return Number(v.toFixed(2)).toLocaleString('en-US')
    }

    const snapshots = ["1st Place", "2nd Place", "3rd Place"]
    let viewing: LeaderboardEntry | undefined;
    if (data) {
        viewing = highlightFilter == '1st Place' ? data[0] : highlightFilter == '2nd Place' ? data[1] : data[2]
    }
    return (<>
        <div className="App-contents">
            <div className="hero">
                <img className="Background-img" src="/chips-cropped.png"/>
                <div className="Hero-Content">
                    <div className="Headline">
                        <h3>Welcome</h3>
                        <h1 style={{ padding: '20px 0 !important'}}>To BTCS</h1>
                        <p>{userDetails ? 'Start watching our streams to earn rewards with Points!' : 'Sign in to start earning points.'}</p>
                    </div>
                    <div className="Streamer-List-Container">
                    <div className="Streamer-List">
                        <Streamers/>
                    </div>
                    </div>

                </div>

            </div>
            <div className="Landing-content">
                <br/><br/>
                <div className="Landing-Coins">
                    <div className="Gold">
                        <img src="/coin/goldcoin.png"/>
                    </div>
                    <div className="Silver">
                        <img src="/coin/silvercoin2.png"/>
                    </div>
                </div>
                <div className="Landing-heading Heading-Landing">
                    <h2 className="Main-heading">Leaderboard</h2>
                    <div className="Heading-divider"/>
                    <p className="Main-subheading">Step into the spotlight with our leaderboard section! See who's
                        crushing it and join the fun as you aim for the top spot among fellow contenders.</p>
                </div>
                <br/><br/>
                <div className="Leaderboard-Pedestal-container">
                    <div className="Leaderboard-Pedestal">
                        <div className="Rankings">
                            <div className="Ranking">
                                <div className="Content Silver">
                                    <div className="User-icon">
                                        <img src={data && data[1] ? data[1].image ?? 'https://btcs.gg/newcoin.svg' : 'https://btcs.gg/newcoin.svg'}/>
                                    </div>
                                    <div className="User-info-container User-info-container-silver">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">${data && data[1] ? data[1].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>${data ? data[1] && format(data[1].totalWagered) : '-'}</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[1] && data[1].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Ranking">
                                <div className="Content Gold">
                                    <div className="User-icon">
                                        <img src={data && data[0] ? data[0].image ?? 'https://btcs.gg/newcoin.svg' : 'https://btcs.gg/newcoin.svg'}/>
                                    </div>
                                    <div className="User-info-container User-info-container-gold">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">${data && data[0] ? data[0].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>${data ? data[0] && format(data[0].totalWagered) : '-'}</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[0] && data[0].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Ranking">
                                <div className="Content Bronze">
                                    <div className="User-icon">
                                        <img src={data && data[2] ? data[2].image ?? 'https://btcs.gg/newcoin.svg' : 'https://btcs.gg/newcoin.svg'}/>
                                    </div>
                                    <div className="User-info-container">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">${data && data[2] ? data[2].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>${data ? data[1] && format(data[2].totalWagered) : '-'}</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[2] && data[2].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Leaderboard-btn" style={{ margin: '50px 0'}}><a href="/leaderboard">
                                <button style={{marginRight: '10px'}}><img width={25} src="https://btcs.gg/challenges-img/leaderboard-update-white.svg" />Full Leaderboard</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Leaderboard-Pedestal-container Leaderboard-Pedestal-container-mobile">
                    <br/><br/><br/>
                    <div className="Leaderboard-Pedestal">
                        <div className="Rankings">
                            <div className="Ranking">
                                <div
                                    className={"Content " + (highlightFilter == '1st Place' ? 'Gold' : highlightFilter == '2nd Place' ? 'Silver' : 'Bronze')}>
                                    <div className="User-icon">
                                        <img src={viewing ? viewing.image ?? 'https://btcs.gg/newcoin.svg' : 'https://btcs.gg/newcoin.svg'}/>
                                    </div>
                                    <div className="User-info-container User-info-container-gold">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">${viewing ? viewing.prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>${viewing ? format(viewing.totalWagered) : '-'}</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{viewing ?  viewing.username : '-'}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="Pedestal-View-Number">
                            <div onClick={() => setHighlightsFilter('1st Place')} className={`First ${highlightFilter == '1st Place' ? 'First-Active' : ''}`}>1</div>
                            <div onClick={() => setHighlightsFilter('2nd Place')} className={`Second ${highlightFilter == '2nd Place' ? 'Second-Active' : ''}`}>2</div>
                            <div onClick={() => setHighlightsFilter('3rd Place')} className={`Third ${highlightFilter == '3rd Place' ? 'Third-Active' : ''}`}>3</div>
                        </div>

                        <br/><br/>

                        <div className="Leaderboard-btn" style={{ margin: '50px 0'}}><a href="/leaderboard">
                            <button style={{marginRight: '10px'}}><img width={25} src="https://btcs.gg/challenges-img/leaderboard-update-white.svg" />Full Leaderboard</button>
                        </a>
                        </div>

                    </div>
                </div>
                <div className="Landing-heading Heading-Landing-2">
                    <h2 className="Main-heading">Latest Videos</h2>
                    <div className="Heading-divider"/>
                    <p className="Main-subheading">Catch the latest and greatest on our Youtube Channel! Subscribe now for endless entertainment and stay updated with our content.</p>
                </div>
                <StreamersVideos/>
                <Footer/>
            </div>
        </div>
    </>)
}