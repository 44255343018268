import {Footer} from "../Components/Footer";
import React from "react";
import CasinoPartners from "../Components/CasinoPartners";
import '../Assets/CSS/Casino.scss'
import '../Assets/CSS/Casino.responsive.scss'
import Streamers from "../Components/Streamers";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Button from "@mui/material/Button";

export default function FAQ() {
    return (
        <div className="App-contents">
            <div className="hero">
                <div className="Hero-Content">
                    <div className="Landing-heading">
                        <h2 className="Main-heading-largest Heading-padded">FAQ</h2>
                        <div className="Heading-divider"/>
                        <p className="Main-subheading" style={{color: 'white'}}>Frequently Asked Questions for BTCs.GG</p>

                        <div className="Casino-card-Container">
                        <div className="Text-Content-card PartnerList">

                            <p>
                                TO BE ADDED.
                                <br />
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
                <br/><br/>
                <br/><br/><br/><br/><br/>
                <Footer/>
        </div>)
    // return (<>
    //     <div className="App-contents">
    //         <br/>
    //         <div className="Profile-general">
    //             <div className="Account-settings-container" style={{width: 'calc(100% - 20px)', overflow: 'hidden'}}>
    //                 <div className="Landing-heading Landing-heading-left">
    //                     <h4>Our Partners</h4>
    //                 </div>
    //                 <div className="PartnerList">
    //                     <CasinoPartners/>
    //                 </div>
    //             </div>
    //         </div>
    //         <br/> <br/>
    //         <Footer/>
    //     </div>
    // </>)
}