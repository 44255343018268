import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import '../Assets/CSS/Profile.scss';
import '../Assets/CSS/Profile.responsive.scss';
import {Item, ItemDefinition, PlayerProfileApi} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import Tabs from "../Components/Tabs/Tabs";
import {Card, CardContent, Grid} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {MenuProps} from "./Hunts";
import {AchievementsTable} from "../Components/AchievementsTable";
import {PlayerItems} from "../Components/PlayerItems";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getProfileDetails = async (): Promise<PlayerProfileApi> => axiosGet(`/player/profile`)

export type ProfileProps = {
    defs: Array<ItemDefinition>
};

export const PlayerProfile: React.FC<ProfileProps> = ({defs}) => {
    const classes = useStyles();

    const [profile, setProfile] = useState<PlayerProfileApi | undefined>(undefined);
    const [streamerFilter, setStreamerFilter] = useState<string>('This Month');

    useEffect(() => {
        getProfileDetails().then((prof: PlayerProfileApi) => {
            setProfile(prof)
        }).catch(e => {
            //@ts-ignore
            window.location.href = '/'
        })
    }, [])

    const Items = (classes: any, defs: Array<ItemDefinition>, inventory?: Array<Item>) => {
        const map = new Map<number, ItemDefinition>()
        defs.map(def => map.set(def.id, def))
        return (<Grid className={classes.gridList} spacing={0} container>
            {inventory!.map((tile) =>
                (
                <Card sx={{ minWidth: 100, maxWidth: 100, minHeight: 100, maxHeight: 100 }}  className={classes.card}>
                    <CardContent className={classes.cardContent} style={{backgroundImage: `url(${defs.find(d => d.id == tile.id)!.image})`}}>
                        <span>
                            {tile.quantity}x
                        </span>
                    </CardContent>
                </Card>
            )
            )}
        </Grid>)
    }

    if (!profile) {
        return (<div className="App-contents"/>)
    }

    const streamers = [ "This Month" ]

    const handleStreamerChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setStreamerFilter(value)
    };

    if (profile.items) {
        profile.items.map(i => {
            const def = defs?.find(d => d.id == i.item)
            i.name = def?.name ?? 'N/A'
        })
    }

    return (<>
        <div className="App-contents">
            <div className="Profile-general To-Row">
                <div className="Account-settings-container Account-settings-container-half">
                    <div className="Landing-heading Landing-heading-left">
                        <h4>General</h4>
                    </div>
                    <div className="Player-overview Player-overview-full">
                        <ul>
                            <li>Username</li><li>{profile ? profile.username : '-'}</li>
                        </ul>
                        <ul>
                            <li>Stream Watch Time</li><li>{profile ? moment.utc(profile.watchTime).format('HH:mm:ss') : '-'}</li>
                        </ul>
                        <ul>
                            <li>Challenges Completed</li>
                            <li>{profile ? profile.challenges : '-'} / {profile ? profile.totalChallenges : '-'}</li>
                        </ul>
                        <ul>
                            <li>Vip Rewards Earned</li>
                            <li>{profile ? profile.vip : '-'} / {profile ? profile.totalVip : '-'}</li>
                        </ul>
                        <ul>
                            <li>Balance</li>
                            <li>
                                <div className="Balance">
                                    <img src="/currency.svg" />
                                    <div  className="Points-value">{profile ? profile.balance.toLocaleString('en-US') : '-'}</div>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li>Total Points Earned</li>
                            <li>
                                <div className="Balance">
                                    <img src="/currency.svg" />
                                    <div  className="Points-value">{profile ? profile.totalBalance.toLocaleString('en-US') : '-'}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="Account-settings-container Account-settings-container-half">
                    <div className="Landing-heading Landing-heading-left">
                        <h4>Achievements</h4>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Month</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={streamerFilter}
                                onChange={handleStreamerChange}

                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }} label="Streamer" />}
                                renderValue={(selected) => selected}
                                MenuProps={MenuProps}
                            >
                                {
                                    streamers.map((variant) => (
                                    //@ts-ignore
                                    <MenuItem key={variant.id} value={variant}>
                                        <ListItemText primary={variant} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <AchievementsTable defs={defs} rows={profile ? profile.achievements : []} />
            
                </div>
            </div>

            <div className="Account-settings-container" style={{width: 'calc(100% - 20px)'}}>
                <div className="Landing-heading Landing-heading-left">
                    <h4>Items</h4>
                </div>
                    <PlayerItems defs={defs} rows={profile ? profile.items : []} />

            </div>

            <br /><br /><br />
            <Footer/>
        </div>
    </>)
}