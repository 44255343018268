import {RafflePurchaseTicketModalProps} from "../Routes/Admin";
import React, {useState} from "react";
import {RaffleTicketRequest, ShuffleRaffle, StoreItem} from "../API/api";
import {axiosPut} from "../Utility/httpClient";
import Dialog from "@mui/material/Dialog";

export default function RaffleTicketWidget(props: RafflePurchaseTicketModalProps) {
    const { onClose, open, viewing } = props;

    const [tickets, setTickets] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const buyRaffleTicket = async (request: RaffleTicketRequest): Promise<Array<ShuffleRaffle>> => axiosPut(`/player/join-raffle`, request)

    const dialogStyles = {
        backgroundColor: '#1a1d23',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '600px',
        maxHeight: '220px',
        overflow: 'hidden'
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form">
                <h2>Bet on Raffle</h2>
                <br />
                <h6>Points</h6>
                <br />
                <div className="Form-input-field">
                    <div className="Form-input">
                        <input type="number" placeholder="Points" defaultValue={tickets}
                               onChange={(e) => {
                                   setTickets(Number(e.target.value))
                               }}/>
                    </div>
                </div>
                <br />
                <h6>Max bet per user is 9999 Points.</h6>
                <div className="Form-submission Form-submission-right">
                    <button onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!tickets) {
                            return
                        }
                        setSubmitting(true)
                        buyRaffleTicket({
                            uuid: viewing.id,
                            entries: tickets
                        }).then(r => {
                            setSubmitting(false)
                            handleClose()
                        })
                    }}>{submitting ? 'Buying...' : 'Bet'}</button>
                </div>
            </div>
        </Dialog>
    );
}