export const PrevMonthChallengesLB = [
    {username: 'ChanChinda', completed: 61, earned: 4003.00, prize: '$4000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smokey1', completed: 36, earned: 2875.00, prize: '$2000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Junkoishere', completed: 21, earned: 1867.00, prize: '$1000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Reelss', completed: 21, earned: 1379.00, prize: '750', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeInThisBih', completed: 19, earned: 1426.00, prize: '600', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'lgd', completed: 15, earned: 1406.00, prize: '500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rekehh', completed: 15, earned: 768.00, prize: '400', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fuqboy', completed: 13, earned: 995.00, prize: '300', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'ElderBTC', completed: 13, earned: 2770.00, prize: '250', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'huncho100', completed: 4, earned: 367.00, prize: '200', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'OopAStae', completed: 3, earned: 285.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'GGE', completed: 2, earned: 310.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '999bredren', completed: 2, earned: 152.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN1', completed: 2, earned: 105.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jollyrogger', completed: 2, earned: 130.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
]

export const PrevMonthWagerLB = [
    {username: '555', totalWagered: 11757116.74, prize: 20000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Pet', totalWagered: 2528096.81, prize: 10000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 1497409, prize: 5000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hat', totalWagered: 863590.38, prize: 2500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Bru', totalWagered: 825151.30, prize: 1250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mtu', totalWagered: 585596.80, prize: 1000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 560840.35, prize: 875, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Eld', totalWagered: 477770.77, prize: 800, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'nam', totalWagered: 274476.86, prize: 750, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Oop', totalWagered: 251255.89, prize: 700, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'War', totalWagered: 190220.42, prize: 650, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Emm', totalWagered: 183518.57, prize: 625, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Vol', totalWagered: 181255.21, prize: 600, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sat', totalWagered: 129927.34, prize: 575, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'das', totalWagered: 129379.93, prize: 550, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cha', totalWagered: 125044.13, prize: 525, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'uhm', totalWagered: 117832.91, prize: 500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'dan', totalWagered: 113832.91, prize: 475, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ven', totalWagered: 112907.08, prize: 450, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cha', totalWagered: 108078.67, prize: 425, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Maa', totalWagered: 105077.06, prize: 400, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Deg', totalWagered: 102515.35, prize: 375, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Shr', totalWagered: 94955.44, prize: 350, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'jun', totalWagered: 82782.22, prize: 325, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fuq', totalWagered: 82706.25, prize: 300, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'dic', totalWagered: 93075.19, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Che', totalWagered: 87070.80, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'DeV', totalWagered: 66916.57, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sco', totalWagered: 58562.57, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rek', totalWagered: 58105.71, prize: 0, image: 'https://btcs.gg/newcoin.svg'}
]