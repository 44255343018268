import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTicketAlt} from "@fortawesome/free-solid-svg-icons";
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {ShuffleGiveaway} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import {formatDateYearTime} from "../Components/Crates";
import {endDate} from "../Utility/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        maxWidth: '600px',
        marginRight: '2%',
        maxHeight: '410px',
        minWidth: 600,
        height: "410px",
        backgroundColor: 'transparent !important',
        // margin: '25px',
        paddingTop: '15px',
        // border: '1px solid #2a2e38',
        boxShadow: 'none !important',
        display: 'inline-flex',
        borderRadius: '30px !important',
    },
    cardMedia: {
        height: '430px',
        maxWidth: '280px',
        minWidth: '280px',
        padding: '0',
        marginTop: '-20px',
        borderTopLeftRadius: '30px !important',
        borderBottomLeftRadius: '20px',
    },
    cardContent: {
        // background: 'linear-gradient(180deg, #041128, #121418)',
        background: '#424659',
        width: '325px',
        height: '410px',
        minHeight: '410px',
        marginTop: '-20px',
        borderTop: '1px solid #2d007d',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        borderTopRightRadius: '30px !important',
        borderBottomRightRadius: '30px !important',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getGiveaways = async (): Promise<Array<ShuffleGiveaway>> => axiosGet(`/general/giveaways`)

export default function Giveaways() {

    const GiveawayList = (data: Array<ShuffleGiveaway>) => {
        const classes = useStyles();
        const [timedKey, setTimedKey] = useState<string>('timed-key-0');

        setInterval(() => {
            // setTimedKey('timed-key-' + new Date().getTime())
        }, 1000)

        return (<Grid className={classes.gridList + ' Giveaway-grid'} key={timedKey} spacing={0} container>
            {
                data.filter(d => tab == 0).map((tile) => {
                    const end = endDate(tile.end)
                    return (
                        <Card className={classes.card + ' Giveaway-grid' + ' Giveaway-card'}>
                            <CardMedia
                                component="img"
                                className={classes.cardMedia}
                                image={tile.image}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className="GiveawayContent">
                                    {/*<span>Ends {formatDateYearTime(tile.end)}</span>*/}
                                    <Typography gutterBottom variant="h6" component="div">
                                        <div className="GiveawayHeading">FOLLOW & WATCH</div>
                                    </Typography>
                                    <ul className="Links">
                                        <li className="StreamerLi"><img src="https://btcs.gg/footer/youtube-icon.svg" width={25} /><p>@{tile.streamer}</p></li>
                                        <li className="StreamerLi"><img src="https://btcs.gg/footer/x-icon.svg" width={20} /><p>/{tile.streamer}</p></li>
                                    </ul>
                                    <div className="Watch-req-Timers">
                                        <ul className="InlineUl">
                                            <li><img src="https://btcs.gg/challenges-img/kick-timer.svg" width={50} /><p className="Watch-req">3 hrs</p></li>
                                            <div />
                                            <li><img src="https://btcs.gg/challenges-img/twitch-timer.svg" width={50} /><p className="Watch-req">12 hrs</p></li>
                                        </ul>
                                    </div>
                                    <br /><br />
                                    <p style={{fontSize: '10px', fontWeight: '700', color: 'white'}}>ENDS IN</p>
                                    <div className="Timer">
                                        <ol>
                                            <li><p>{end[0]}</p></li>
                                            <li><p>Days</p></li>
                                        </ol>
                                        <div />
                                        <ol>
                                            <li><p style={{ fontWeight: '700' }}>{end[1]}</p></li>
                                            <li><p>Hours</p></li>
                                        </ol>
                                        <div />
                                        <ol>
                                            <li><p style={{ fontWeight: '700' }}>{end[2]}</p></li>
                                            <li><p>Mins</p></li>
                                        </ol>
                                    </div>
                                    <br/>
                                    <a href={tile.link} target="_action">
                                        <div className="GiveawayAction">
                                            Join Here
                                        </div>
                                    </a>
                                </div>
                            </CardContent>
                        </Card>
                    );
                })}
        </Grid>);
    }

    const [data, setData] = useState<Array<ShuffleGiveaway>>([]);
    const [tab, setTab] = useState<number>(0);

    useEffect(() => {
        getGiveaways().then(r => {
            setData(r)
        });
    }, [])

    return (<>
        <div className="App-contents Challenges VIP">

            <div className="Landing-content">
                <br/><br/>
                <div className="Landing-heading">
                    <h2 className="Main-heading Main-heading-larger">Giveaways</h2>
                    <div className="Heading-divider"/>
                    <p className="Main-subheading">Multiple Shuffle & RuneScape Giveaways active every week!</p>
                    {/*<a href="https://btcs.gg/raffles"><Button size="small" className="Rules-button">*/}
                    {/*    <FontAwesomeIcon icon={faTicketAlt}/>*/}
                    {/*    <p className="Rules-btn">Raffles</p>*/}
                    {/*</Button></a>*/}
                </div>
                <br/><br/><br/>


                <div className="Bonus-hunts-container">
                    <div className="Bonus-hunts-content-container" style={{maxWidth: '1300px'}}>
                        <div className="Active-filter CenteredFilter">
                            <div className={tab == 0 ? `Active` : ''} onClick={() =>setTab(0)}>RuneScape</div>
                            <div className={tab == 1 ? `Active` : ''} onClick={() =>setTab(1)}><img style={{marginTop:'-2px'}} src="/white.png" /></div>
                        </div>

                        <br/><br/><br/>
                        <br/><br/>


                        <div className="GiveawayList">
                            {GiveawayList(data)}
                        </div>

                    </div>
                    </div>
                <br/> <br/>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <Footer/>
        </div>
    </>)
}