import { calculateVIPRewards, numberWithCommas } from "../Utility/utils"

export const WagerLB = ({rankings}: {rankings: any}) => {
    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div className="Account-settings-container Leaderboard-container Leaderboard-container-Less-padding"
                         style={{width: '800px', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', padding: '0', borderRadius: '5px', border: '3px solid #7e8ab3'}}>
                        {/*{LeaderboardFilterBar(snapshotFilter, setSnapshotFilter)}*/}

                        
                        <ul className="Heading">
                            <li style={{paddingLeft: '10px'}}><span>Rank</span></li>
                            <li><span>User</span></li>
                            <li><span>Wagered</span></li>
                            <li><span>LB Prize</span></li>
                            <li><span><a href={"https://btcs.gg/vip"}>VIP Earned</a></span></li>
                        </ul>
                        {
                            rankings.slice(3).map((entry: any, index: number) => {
                                return (<ul>
                                    <li><p>{index + 4}</p></li>
                                    <li style={{display: 'flex', justifyContent: 'left'}}>
                                        <img width={30} style={{borderRadius: '50%', padding: '0 15px'}} src={entry.image ?? 'https://btcs.gg/newcoin.svg'} />
                                        {entry.username}...
                                    </li>
                                    <li>${numberWithCommas(entry.totalWagered)}</li>
                                    <li>${entry.prize ?? '-'}</li>
                                    <li>${calculateVIPRewards(entry.totalWagered)}</li>
                                </ul>)
                            })
                        }
                    </div>
                </div>
    )
}