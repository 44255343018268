import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import '../Assets/CSS/Bonushunts.scss';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {BonusHunt, BonusHuntOverview, BTCsStreamers, Streamer} from "../API/api";
import {BonusHuntTable} from "../Components/BonusHuntTable";
import {axiosGet} from "../Utility/httpClient";
import {numberWithCommas} from "../Utility/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            color: '#acacac',
            background: '#121418'
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        maxWidth: 300,
        height: "500px",
        backgroundColor: 'transparent !important',
        margin: '25px',
        paddingTop: '15px',
        border: '1px solid #2a2e38',
        boxShadow: 'none',
    },
    cardMedia: {
        height: '280px',
        padding: '0',
        marginTop: '-20px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    cardContent: {
        background: 'linear-gradient(180deg, #041128, #1e1826)',
        height: '200px',
        borderTop: '1px solid #2d007d',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece'
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getStreamersList = async (): Promise<BTCsStreamers> => axiosGet(`/general/streamers`)
const getHuntsOverview = async (): Promise<BonusHuntOverview> => axiosGet(`/general/bonus-hunts`);

export default function Hunts() {

    const HuntsFilterBar = (
        streamer: Array<Streamer>,
        durationFilter: string,
        streamerFilter: string,
        setDurationFilter: (filter: any) => any,
        setStreamerFilter: (filter: any) => any
    ) => {
        const classes = useStyles();

        const handleDurationChange = (event: any) => {
            const {
                target: {value},
            } = event;
            setDurationFilter(value)
        };

        const handleStreamerChange = (event: any) => {
            const {
                target: {value},
            } = event;
            setStreamerFilter(value)
        };

        const time = ["All Time", "Last 24 Hours", "Last 7 Days", "Last 30 Days"]
        const streamers = ["All Streamers"]
        streamer.map(s => streamers.push(s.channelName))
        return (<div className="Bonus-hunts-list-filter">
            <div className="Bonus-hunt-filter">
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id="demo-multiple-checkbox-label" sx={{color: 'white'}}>Duration</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        className={classes.select}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                                root: classes.root,
                            },
                        }}
                        id="demo-multiple-checkbox"
                        value={durationFilter}
                        onChange={handleDurationChange}
                        sx={{
                            color: "white",
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "white !important",
                            }
                        }}

                        input={<OutlinedInput sx={{color: 'white'}} label="Duration"/>}
                        renderValue={(selected) => selected}
                        MenuProps={MenuProps}
                    >
                        {
                            time.map((variant) => (
                                //@ts-ignore
                                <MenuItem key={variant.id} value={variant}>
                                    <ListItemText primary={variant}/>
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
            <div className="Bonus-hunt-filter">
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id="demo-multiple-checkbox-label" sx={{color: 'white'}}>Streamer</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        className={classes.select}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                                root: classes.root,
                            },
                        }}
                        id="demo-multiple-checkbox"
                        value={streamerFilter}
                        onChange={handleStreamerChange}

                        sx={{
                            color: "white",
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2a2e38',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "white !important",
                            }
                        }}

                        input={<OutlinedInput sx={{color: 'white'}} label="Streamer"/>}
                        renderValue={(selected) => selected}
                        MenuProps={MenuProps}
                    >
                        {
                            streamers.map((variant) => (
                                //@ts-ignore
                                <MenuItem key={variant.id} value={variant}>
                                    <ListItemText primary={variant}/>
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        </div>);
    }

    const [data, setData] = useState<BonusHuntOverview | undefined>(undefined);
    const [streamers, setStreamers] = useState<Array<Streamer>>([]);
    const [durationFilter, setDurationFilter] = useState<'All Time' | 'Last 24 Hours' | 'Last 7 Days' | 'Last 30 Days'>('All Time');
    const [streamerFilter, setStreamerFilter] = useState<string>('All Streamers');
    const [visibleData, setVisibleData] = useState<BonusHuntOverview | undefined>(undefined);

    function getVisibleHunts(hunts: Array<BonusHunt>): Array<BonusHunt> {
        let visibleHunts: Array<BonusHunt> = []
        hunts.forEach((hunt, index) => {
            hunt.order = `${hunts.length - index}`
            let earliestEntry = undefined
            switch (durationFilter) {
                case 'Last 24 Hours': {
                    earliestEntry = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
                    break
                }
                case 'Last 7 Days': {
                    earliestEntry = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
                    break
                }
                case 'Last 30 Days': {
                    earliestEntry = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
                    break
                }
            }
            const durationReq = earliestEntry == undefined || earliestEntry.getTime() < new Date(hunt.creationDate).getTime()
            if ((streamerFilter == 'All Streamers' || streamerFilter == hunt.streamer) && durationReq) {
                visibleHunts.push(hunt)
            }
        })
        return visibleHunts
    }

    useEffect(() => {
        setVisibleData({
            totalHunts: 0,
            totalCost: 0,
            profit: 0,
            hunts: getVisibleHunts(data ? data.hunts : [])
        })
    }, [durationFilter, streamerFilter]);

    useEffect(() => {
        getStreamersList().then(response => {
            setStreamers(response.streamers)
        })
        getHuntsOverview().then(response => {
            setData(response)
            setVisibleData({
                totalHunts: 0,
                totalCost: 0,
                profit: 0,
                hunts: getVisibleHunts(response.hunts)
            })
        })
    }, [])

    return (
        <div className="App-contents Challenges VIP">

            <div className="Landing-content">
                <br/><br/>
                <div className="Landing-heading">
                    <h2 className="Main-heading Main-heading-larger">Bonus Hunts</h2>
                    <div className="Heading-divider"/>
                </div>
                <br/><br/><br/><br/>
                <div className="Bonus-hunts-container">
                    <div className="Bonus-hunts-content-container">
                    <div className="Bonus-hunts-stat-container">
                        <div className="Bonus-hunts-stat">
                            <ul className="Stat-icon">
                                <li>
                                    <img src="/hunts-img/hunt-icon.svg" />
                                </li>
                            </ul>
                            <ul>
                                <li className="Value">Hunts</li>
                                <li className="Heading">{data ? data.totalHunts : '-'}</li>
                            </ul>
                        </div>
                        <div className="Bonus-hunts-stat">
                            <ul className="Stat-icon">
                                <li>
                                    <img src="/hunts-img/money-icon.svg" />
                                </li>
                            </ul>
                            <ul>
                                <li className="Value">Cost</li>
                                <li className="Heading">${data ? numberWithCommas(data.totalCost) : '-'}</li>
                            </ul>
                        </div>
                        <div className="Bonus-hunts-stat">
                            <ul className="Stat-icon">
                                <li>
                                    <img src="/hunts-img/profit-icon.svg" />
                                </li>
                            </ul>
                            <ul>
                                <li className="Value">Profit</li>
                                <li className="Heading">${data ? numberWithCommas(data.profit) : '-'}</li>
                            </ul>
                        </div>
                        </div>
                        {
                            HuntsFilterBar(streamers, durationFilter, streamerFilter, setDurationFilter, setStreamerFilter)
                        }
                        <BonusHuntTable
                            key={durationFilter + streamerFilter}
                            rows={visibleData ? visibleData.hunts : []}
                            setBonusHunt={(hunt: BonusHunt | undefined) => {
                                if (hunt) {
                                    window.location.href = '/hunt/' + hunt.id + '/'
                                }
                            }}
                        />

                    </div>
                </div>
                <br/><br/><br/><br/>
                <Footer/>
            </div>
        </div>)
}