import React, {useEffect, useState} from 'react';
import './Assets/CSS/App.scss';
import './Assets/CSS/App.responsive.scss';
import {Header, Login} from "./Components/Header";
import {ItemDefinition, PlayerDetails, TwitchAuthCodeGrantFlow} from "./API/api";
import {axiosGet} from "./Utility/httpClient";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Landing} from "./Routes/Landing";
import {PlayerProfile} from "./Routes/PlayerProfile";
import Challenges from "./Routes/Challenges";
import Admin from "./Routes/Admin";
import Highlights from "./Routes/Highlights";
import Leaderboard from "./Routes/Leaderboard";
import Casinos from "./Routes/Casinos";
import {Store} from "./Routes/Store";
import {SlotBattles} from "./Routes/SlotBattles";
import Cases from "./Routes/Cases";
import Hunts from "./Routes/Hunts";
import Hunt from "./Routes/Hunt";
import AdminRedirect from "./Routes/AdminRedirect";
import {VIP} from "./Routes/VIP";
import Giveaways from "./Routes/Giveaways";
import {Raffles} from "./Routes/Raffles";
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import {Raffle} from "./Routes/Raffle";
import {PlayerSettings} from "./Routes/PlayerSettings";
import TermsOfService from "./Routes/TermsOfService";
import FAQ from "./Routes/FAQ";
import PrivacyPolicy from "./Routes/PrivacyPolicy";

const getAccessToken = async (code: string): Promise<TwitchAuthCodeGrantFlow> => axiosGet(`/auth/token?code=${code}`)
const discordIntegration = async (code: string): Promise<any> => axiosGet(`/player/discord-callback?code=${code}`)
const getProfileDetails = async (): Promise<PlayerDetails> => axiosGet(`/player/details`)
const getItemDefinitions = async (): Promise<Array<ItemDefinition>> => axiosGet(`/general/item-defs`)

export default function App() {

    const [panelVisible, setPanelVisible ] = React.useState<boolean>(false)

    async function checkTwitchLogin() {
        const url = window.location.href;
        const codeMatches = url.match(/code=([^&]*)/);
        const errorMatches = url.match(/error=([^&]*)/);
        let code = undefined;
        let error = undefined;
        const hasToken = localStorage.getItem("bearer") != null;
        if (codeMatches) {
            code = codeMatches[0].substring(5);
        } else if (errorMatches) {
            error = errorMatches[0].substring(6);
        }
        if (code) {
            if (localStorage.getItem("discord_integration") != null) {
                const valid = Number(localStorage.getItem("discord_integration")) > new Date().getTime()
                localStorage.removeItem("discord_integration");
                if (valid) {
                    await discordIntegration(code).then(r => {
                        //@ts-ignore
                        window.location.href = process.env.REACT_APP_TWITCH_REDIRECT_URI;
                    }).catch(e => {
                        //@ts-ignore
                        window.location.href = process.env.REACT_APP_TWITCH_REDIRECT_URI;
                    });
                    return;
                }
            }

            if (hasToken) {
                localStorage.removeItem("bearer");
                localStorage.removeItem("refresh");
                localStorage.removeItem("expiry");
            }
            localStorage.setItem("twitch", code);
            await fetchToken();
            //@ts-ignore
            window.location.href = process.env.REACT_APP_TWITCH_REDIRECT_URI;
        } else if (error) {
            if (hasToken) {
                localStorage.removeItem("bearer");
                localStorage.removeItem("refresh");
                localStorage.removeItem("expiry");
            }
        } else if (hasToken) {
            if (localStorage.getItem("bearer")!.toString().length > 0) {
                getProfileDetails().then((response: PlayerDetails) => {
                    setUserDetails(response)
                }).catch(err => {
                    localStorage.removeItem("bearer");
                    localStorage.removeItem("refresh");
                    localStorage.removeItem("expiry");
                })
            }
        }
    }

    async function fetchToken() {
        while (localStorage.getItem("bearer") == null || localStorage.getItem("bearer")!.length == 0) {
            await getAccessToken(localStorage.getItem("twitch")!).then(response => {
                localStorage.setItem("bearer", response.access_token);
                localStorage.setItem("refresh", response.refresh_token);
                localStorage.setItem("expiry", `${response.expires_in}`);
            });
        }
    }

    const [userDetails, setUserDetails] = useState<PlayerDetails | undefined>(undefined);
    const [defs, setDefs] = useState<Array<ItemDefinition> | undefined>(undefined);

    useEffect(() => {
        checkTwitchLogin()
        getItemDefinitions().then(defs => {
            setDefs(defs)
        })
    }, [])

    if (!defs) {
        return (<div className="App"></div>);
    }

    const toggleSelector = panelVisible ? '' : 'Wagers-toggle-hidden';
    const {url} = Login()
    const isAdmin = userDetails && userDetails.authority != 'USER'
    return (
        <div className="App">
            <Header userDetails={userDetails} togglePanelVisible={() => setPanelVisible(true)} />
            <div className="App-contents-container">
                <BrowserRouter>
                    <Routes>
                        <Route element={<Landing userDetails={userDetails} />} path="/" />
                        <Route element={<VIP userDetails={userDetails} defs={defs}/>} path="/vip" />
                        <Route element={<Admin/>} path="/admin/:page/:model?" />
                        <Route element={<AdminRedirect/>} path="/admin" />
                        <Route element={<Casinos/>} path="/casino" />
                        <Route element={<Leaderboard/>} path="/leaderboard" />
                        <Route element={<Highlights/>} path="/highlights" />
                        <Route element={<Challenges/>} path="/challenges" />
                        <Route element={<Giveaways/>} path="/giveaways" />
                        <Route element={<Raffles userDetails={userDetails} />} path="/raffles" />
                        <Route element={<Raffle userDetails={userDetails} />} path="/raffle/:raffle/" />
                        <Route element={<Cases/>} path="/cases" />
                        <Route element={<SlotBattles userDetails={userDetails} />} path="/battles" />
                        <Route element={<Hunts/>} path="/hunts" />
                        <Route element={<Hunt/>} path="/hunt/:hunt/" />
                        <Route element={<Store userDetails={userDetails} setUserDetail={setUserDetails} />} path="/store" />
                        <Route element={<PlayerProfile defs={defs} />} path="/user" />
                        <Route element={<PlayerSettings defs={defs} />} path="/settings" />
                        <Route element={<TermsOfService/>} path="/tos" />
                        <Route element={<FAQ/>} path="/faq" />
                        <Route element={<PrivacyPolicy/>} path="/privacy-policy" />
                    </Routes>
                </BrowserRouter>
            </div>
            <div className={`Mobile-navigation ${toggleSelector}`} onClick={() => {
                setPanelVisible(!panelVisible)}
            }>
                    <div className="Mobile-Close">
                        <div className="Action">
                            <CloseIcon />
                        </div>
                        <div className="Text">
                            <p>Menu</p>
                        </div>
                    </div>
                    <div className="Mobile-Balance">
                        <div className="Balance">
                            <img src="/currency.svg" />
                            <span>{userDetails?.points.toLocaleString('en-US') ?? 0 }</span>
                        </div>
                    </div>
                    <div className="Mobile-Profile">
                        <div className="Profile-image">
                            <img src={userDetails?.profilePicture ?? "/def.png"} />
                        </div>
                        <div className="Profile-name">
                            {
                                userDetails ? <span>{userDetails.username}</span> :
                                <a href={url}>
                                    <button>Login</button>
                                </a>
                            }
                            
                        </div>
                        <div className="Profile-rank">
                            {userDetails ? <span>{userDetails.authority}</span> : <></>}
                        </div>
                    </div>
                    <div className="Mobile-Menu">
                        {
                            !userDetails ?
                            <ul>
                                <li>
                                    <a href="https://btcs.gg/">
                                        <div className="Menu-icon"><HomeIcon /></div>
                                        <div className="Menu-option"><span>Home</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/vip">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/vip-09 copy.svg" /></div>
                                        <div className="Menu-option"><span>VIP</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/casino">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/casino copy.svg" /></div>
                                        <div className="Menu-option"><span>Casino</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/battles">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/vs copy.svg" /></div>
                                        <div className="Menu-option"><span>Slot Battles</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/challenges">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/challenges copy.svg" /></div>
                                        <div className="Menu-option"><span>Challenges</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/hunts">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/hunts copy.svg" /></div>
                                        <div className="Menu-option"><span>Hunts</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/store">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/cart copy.svg" /></div>
                                        <div className="Menu-option"><span>Store</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/highlights">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/highlights copy.svg" /></div>
                                        <div className="Menu-option"><span>Highlights</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/giveaways">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/giveaway copy.svg" /></div>
                                        <div className="Menu-option"><span>Giveaways</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/leaderboard">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/leaderboard copy.svg" /></div>
                                        <div className="Menu-option"><span>Leaderboard</span></div>
                                    </a>
                                </li>
                            </ul> :
                        <ul>
                            <li>
                                <a href="https://btcs.gg/user">
                                    <div className="Menu-icon"><img width={25} src="/navigation/icons/profile.svg" /></div>
                                    <div className="Menu-option"><span>Profile</span></div>
                                        </a>
                                </li>
                            <li>
                                <a href="https://btcs.gg/settings">
                                    <div className="Menu-icon"><img width={25} src="/navigation/icons/setting.svg" /></div>
                                    <div className="Menu-option"><span>Settings</span></div>
                                </a>
                            </li>
                                {
                                    isAdmin ? <li><a href="https://btcs.gg/admin/dashboard">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/admin.svg" /></div>
                                        <div className="Menu-option"><span>Admin</span></div>
                                    </a>
                                    </li> : <></>
                                }
                            <li>
                                <a href="/" onClick={() => {
                                    localStorage.removeItem("bearer");
                                    localStorage.removeItem("refresh");
                                    localStorage.removeItem("expiry");
                                    localStorage.removeItem("twitch");
                                    window.location.reload()
                                }}>
                                    <div className="Menu-icon"><img width={25} src="/navigation/icons/logout-13.svg" /></div>
                                    <div className="Menu-option"><span>Logout</span></div>
                                </a>
                            </li>
                                <li>
                                    <a href="https://btcs.gg/">
                                        <div className="Menu-icon"><HomeIcon /></div>
                                        <div className="Menu-option"><span>Home</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/vip">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/vip-09 copy.svg" /></div>
                                        <div className="Menu-option"><span>VIP</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/casino">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/casino copy.svg" /></div>
                                        <div className="Menu-option"><span>Casino</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/battles">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/vs copy.svg" /></div>
                                        <div className="Menu-option"><span>Slot Battles</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/challenges">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/challenges copy.svg" /></div>
                                        <div className="Menu-option"><span>Challenges</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/hunts">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/hunts copy.svg" /></div>
                                        <div className="Menu-option"><span>Hunts</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/store">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/cart copy.svg" /></div>
                                        <div className="Menu-option"><span>Store</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/highlights">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/highlights copy.svg" /></div>
                                        <div className="Menu-option"><span>Highlights</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/giveaways">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/giveaway copy.svg" /></div>
                                        <div className="Menu-option"><span>Giveaways</span></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://btcs.gg/leaderboard">
                                        <div className="Menu-icon"><img width={25} src="/navigation/icons/leaderboard copy.svg" /></div>
                                        <div className="Menu-option"><span>Leaderboard</span></div>
                                    </a>
                                </li>
                        </ul>
                        }
                    </div>
                    <div className="Mobile-Tos">
                        <div className="Tos">
                            <span>Privacy Policy</span>
                            <span>Terms of Service</span>
                        </div>
                    </div>
            </div>
        </div>
    );
}

