import React, {useEffect} from "react";
import '../Assets/CSS/Carousel.scss'
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

export type CarouselProps = {
    videos: Array<JSX.Element>
};

export const VideoCarousel: React.FC<CarouselProps> = ({ videos }) => {

    const [ currentDegree, setCurrentDegree ] = React.useState<number>(0)
    const [ currentVideo, setCurrentVideo ] = React.useState<number>(0)

    const carousel = React.useRef<any>();
    const items: Array<HTMLDivElement> = [];

    const rotate = (next: boolean) => {
            setCurrentVideo(currentVideo + (next ? 1 : -1))
            if (currentVideo < 0) {
                // setCurrentVideo(5);
            }
            if (currentVideo > 5) {
                // setCurrentVideo(0);
            }
        setCurrentDegree(currentDegree + (60 * (next ? -1 : 1)));
        carousel.current.style.transform = `rotateY(${currentDegree}deg)`
        items.forEach(item => {
            item.style.transform = `rotateY(${-currentDegree}deg)`
        })
    }

    useEffect(() => {

        rotate(true)
        setCurrentVideo(0)
        carousel.current.style.transform = `rotateY(0deg)`
        items.forEach(item => {
            item.style.transform = `rotateY(0deg)`
        })

    }, [])

    const selectors = [ 'a', 'b', 'c', 'd', 'e', 'f', ]

    return (
        <>
            <div className="carousel-container">
                <div className="carousel" ref={carousel}>
                    {
                        videos.map((video, index) => {
                            return (<div className={selectors[index]}>
                                <div className={"item " + (currentVideo == index ? 'Viewing' : '')} ref={r => { if (r) items.push(r) }}>
                                    <div className="item-backdrop">
                                        {video}
                                    </div>
                                </div>
                            </div>)
                        })
                    }
                </div>
                <div className="next">
                    <KeyboardDoubleArrowRightIcon  onClick={() => rotate(true)} />
                </div>
                <div className="prev">
                    <KeyboardDoubleArrowLeftIcon onClick={() => rotate(false)} />
                </div>
            </div>
        </>
    )
}